
/*   -=========== Vitrine ===========-   */

.vitrine {
  display: flex;
  flex-wrap: wrap;

  &:not(.vitrine_no-top-border) {
    border-top: 1px solid $almost-white;
  }

  .product {
    box-sizing: border-box;
    border-bottom: 1px solid $almost-white;
    border-right: 1px solid $almost-white;
    position: relative;

    &:hover {
      z-index: 10;
    }

    @media $desktop {
      flex-basis: (100% / 6);
    }

    @media $notebook {
      flex-basis: (100% / 5);
    }

    @media $landscape-tablet {
      flex-basis: (100% / 4);
    }

    @media $portrait-tablet {
      flex-basis: (100% / 3);
    }

    @media $small-mobile {
      flex-basis: 100%;
    }

    &:before {
      content: '';
      display: block;
      padding-bottom: 148%;
    }
  }

  .product--content {
    box-sizing: border-box;
    width: 100%; height: 100%;
    padding: 20px 25px 45px;
    position: absolute;
    left: 0; top: 0;
  }

  .product--image {
    display: block;
    padding-bottom: 100%;
    position: relative;

    img {
      position: absolute;
      left: 50%; top: 50%;
      transform: translate(-50%, -50%);
      max-height: 100%;
    }
  }

  .product--availability {
    position: absolute;
    left: 25px; top: 25px;
    font-size: 1.1rem;
    color: $gray;
  }

  .product--icons {
    @extend .product-icons-prototype;
    position: absolute;
    right: 12px; top: 22px;
  }

  .product--name {
    text-align: center;
    margin-top: 10px;

    @media $not-large-desktop {
      overflow: hidden;
      max-height: 4em;
    }

    @media $tablet {
      overflow: hidden;
      max-height: 4em;
    }

    a {
      font-size: 1.4rem;
      color: $black;
      text-decoration: none;
    }
  }

  .product--footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 50px);
    position: absolute;
    bottom: 45px; left: 25px;
  }

  .product--old-price {
    font-size: 1.4rem;
    color: $gray;
    text-decoration: line-through;

    .currency {
      font-size: .9em;
    }
  }

  .product--price {
    font-size: 2.4rem;
    font-weight: bold;
    white-space: nowrap;

    .currency {
      font-size: 1.8rem;
    }
  }

  .product--buy-btn {
    @extend .btn, .h40btn, .rounded-btn;
    width: 104px;

    &:not(.disabled) {
      @extend .turq-btn, .black-btn_hover;
    }

    &.disabled {
      @extend .black-btn;
    }

    &.already-in-basket {
      bordered-btn(2px, $black);
      background: transparent !important;
      color: $black !important;
    }
  }

  .product--remove {
    position: absolute;
    top: 0; right: 0;
    z-index: 10;
    font-size: 0;
    width: 40px; height: 40px;

    &:before, &:after {
      content: '';
      display: block;
      width: 2px; height: 30px;
      background: $light-gray;
      position: absolute;
      top: 50%;
      margin-top: -14px;
      transition: background-color .25s ease;
      box-shadow: 0 0 2px $white;
    }

    &:before {
      left: 50%;
      transform: rotate(-45deg) translateX(-50%);
    }

    &:after {
      right: 50%;
      transform: rotate(45deg) translateX(50%);
    }

    &:hover {
      &:before, &:after {
        background: $black;
      }
    }
  }

  .product-price--currency {
    @extend .currency;
  }
}

.vitrine_two-lines {
  @media $notebook {
    .product:nth-child(9) ~ .product {
      display: none;
    }
  }

  @media $mobile {
    .product:nth-child(3) ~ .product {
      display: none;
    }

    .additional-vitrine--header {
      display: block;
      background: $turq;

      .additional-vitrine--caption {
        font-size: 1.8rem;

        br {
          display: none;
        }
      }

      &:before {
        display: none;
      }
    }

    .additional-vitrine--header-content {
      position: relative;
      padding-top: 20px;
      padding-bottom: 20px;
    }

    a.additional-vitrine--header .additional-vitrine--caption .arrow-icon {
      left: auto; right: 0; top: 50%;
      transform: translateY(-50%);
      margin-top: 0;
    }

    .additional-vitrine--caption {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
}

.vitrine--header {
  @extend .vitrine .product;

  &:after {
    content: '';
    display: block;
    width: 100%; height: 50px;
    background: linear-gradient(to bottom, rgba($turq, 0.2) 0%, rgba($turq, 1) 90%);
    transition: opacity .25s ease;
    position: absolute;
    left: 0; bottom: 0;
    z-index: 10;
  }

  &:hover:after {
    opacity: 0;
  }

  @media $mobile {
    &:before {
      display: none;
    }
  }
}

.vitrine--header-content {
  @extend .vitrine .product--content;
  background: $turq;
  padding-top: 50px;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 50px;
  overflow: hidden;
  min-height: 100%;

  @media only screen and (max-width: 1800px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  &:hover {
    height: auto;
  }

  @media $mobile {
    position: relative;
  }
}

.vitrine--header-caption {
  font-size: 3rem;
  font-weight: bold;
  padding-bottom: 25px;
  margin-bottom: 27px;
  position: relative;
  text-transform: lowercase;

  @media $not-large-desktop {
    font-size: 2.4rem;
  }

  &:after {
    content: '';
    display: block;
    width: 32px; height: 2px;
    background: $black;
    position: absolute;
    left: 0; bottom: 0;
  }
}

.vitrine--header-description {
  p + p {
    margin-top: 1em;
  }
}


// Additional vitrine

.additional-vitrine {
  overflow: hidden;

  @media $not-mobile {
    display: flex;
  }

  @media $mobile {
    .product {
      border-top: none;
    }
  }

  .slick-track {
    margin-left: 0;
    margin-right: 0;
  }
}

.additional-vitrine--header {
  @extend .vitrine .product;
  text-transform: lowercase;
  background: $turq;
  text-decoration: none;

  @media $desktop {
    flex-basis: (100% / 6);
  }

  @media $notebook {
    flex-basis: (100% / 5);
  }

  @media $landscape-tablet {
    flex-basis: (100% / 4);
  }

  @media $portrait-tablet {
    flex-basis: (100% / 3);
  }

  @media $mobile {
    display: none;
  }

  .vitrine + & {
    border-left: 1px solid $almost-white;
  }
}

a.additional-vitrine--header {
  .additional-vitrine--caption {
    &:after {
      display: none;
    }
  }

  .additional-vitrine--caption .arrow-icon {
    display: block;
    width: 15px; height: 26px;
    position: absolute;
    left: 0;
    margin-top: 35px;

    &:before {
      content: '';
      display: block;
      width: 16px; height: @width;
      border-top: 2px solid $black;
      border-right: 2px solid $black;
      transform: rotate(45deg);
      margin-left: -7px;
      margin-top: 4px;
    }
  }
}

.additional-vitrine--header_with-bg {
  background: $turq url(/i/slider-caption-bg.png) no-repeat 50% / cover;
  border-bottom: none;
}

.additional-vitrine--header-content {
  @extend .vitrine .product--content;
  padding-top: 50px;
  padding-left: 50px;

  @media only screen and (max-width: 1800px) {
    padding-left: 20px;
  }
}

.vitrine_slider {
  display: block;
  overflow: hidden;
  border-top: none;

  @media $desktop {
    flex-basis: (100% / 6) * 5;
  }

  @media $notebook {
    flex-basis: (100% / 5) * 4;
  }

  @media $landscape-tablet {
    flex-basis: (100% / 4) * 3;
  }

  @media $portrait-tablet {
    flex-basis: (100% / 3) * 2;
  }

  @media $small-mobile {
    flex-basis: 100%;
  }

  .product {
    border-top: 1px solid $almost-white;
    border-left: 1px solid $almost-white;
    border-right: none;
    border-bottom: none;
    outline: none;
  }

  .slick-track {
    white-space: nowrap;

    .product {
      display: inline-block;
      vertical-align: top;
      white-space: normal;
      float: none;
    }
  }
}

.additional-vitrine--controls {
  margin-top: 30%;
}

.additional-vitrine--arrow {
  @extend .btn;
  width: 17px; height: 26px;
  background: transparent;

  &:before {
    content: '';
    display: block;
    width: 15px; height: @width;
    border-top: 2px solid $black;
  }
}

.additional-vitrine--arrow_prev {
  &:before {
    border-left: 2px solid $black;
    transform: rotate(-45deg);
    margin-left: 5px;
  }
}

.additional-vitrine--arrow_next {
  margin-left: 60px;

  &:before {
    border-right: 2px solid $black;
    transform: rotate(45deg);
    margin-right: 5px;
  }
}

.additional-vitrine--caption {
  font-size: 3rem;
  font-weight: bold;
  padding-bottom: 25px;
  margin-bottom: 27px;
  position: relative;

  @media $not-large-desktop {
    font-size: 2.4rem;
  }

  &:after {
    content: '';
    display: block;
    width: 32px; height: 2px;
    background: $black;
    position: absolute;
    left: 0; bottom: 0;
  }
}

.additional-vitrine--description {
  p + p {
    margin-top: 1em;
  }
}
