@font-face {
  font-family: 'Open Sans';
  src: url('/i/fonts/opensans-semibold-webfont.woff2') format('woff2'),
       url('/i/fonts/opensans-semibold-webfont.woff') format('woff');
  font-weight: $semi-bold;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('/i/fonts/opensans-bold-webfont.woff2') format('woff2'),
       url('/i/fonts/opensans-bold-webfont.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

/* @font-face {
  font-family: 'Open Sans';
  src: url('/i/fonts/opensans-extrabold-webfont.woff2') format('woff2'),
       url('/i/fonts/opensans-extrabold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
} */

@font-face {
  font-family: 'Open Sans';
  src: url('/i/fonts/opensans-light-webfont.woff2') format('woff2'),
       url('/i/fonts/opensans-light-webfont.woff') format('woff');
  font-weight: $light;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('/i/fonts/opensans-regular-webfont.woff2') format('woff2'),
       url('/i/fonts/opensans-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'rouble';
  src: url('/i/fonts/rouble-webfont.woff2') format('woff2'),
       url('/i/fonts/rouble-webfont.woff') format('woff');
}
