
/*   -=========== Product card ===========-   */

.product-card {
  .row + .row:not(.row_no-margin) {
    margin-top: 65px;
  }

  @media $portrait-tablet {
    padding-top: 20px;
  }

  .column {
    flex: 1;
    overflow: hidden;

    @media $small-handheld {
      width: 100%;
    }
  }

  .column_gallery {
    display: flex;
    justify-content: space-between;
    position: relative;

    @media $small-handheld {
      width: 100%;
    }
  }

  @media $not-small-handheld {
    .column_right {
      margin-left: 55px;
    }
  }

  @media $handheld {
    .only-desktop + .column_right {
      margin-left: 0;
    }
  }

  @media $small-handheld {
    .row {
      flex-direction: column;
    }
  }
}

.product-card--gallery,
.product-card--main-image {
  a {
    display: block;
    text-align: center;
  }

  img {
    display: inline-block;
    max-height: 600px;
  }
}

.product-card--gallery {
  width: 85px;
  white-space: nowrap;

  @media $mobile {
    width: 44px;
  }

  .slick-slider {
    padding-top: 55px;
    padding-bottom: 42px;

    @media $mobile {
      padding-top: 35px;
      padding-bottom: 27px;
    }
  }

  .item {
    display: inline-block;
    box-sizing: border-box;
    width: 85px; height: @width;
    position: relative;
    border: 1px solid $gray;
    margin-bottom: 13px;
    outline: none;
    cursor: pointer;

    @media $mobile {
      width: 44px; height: @width;
      margin-bottom: 7px;
    }

    img {
      object-fit: cover;
      width: 100%; height: 100%;
    }
  }

  .slick-current .item {
    border-color: $black;
  }

  .slick-arrow {
    display: block;
    width: 40px; height: 40px;
    background: transparent;
    font-size: 0;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    &:before {
      content: '';
      display: block;
      width: 8px; height: @width;
      border-top: 2px solid $black;
      border-right: 2px solid $black;
      position: absolute;
      left: 50%; top: 50%;
    }
  }

  .slick-prev {
    top: 0;

    &:before {
      transform: rotate(-45deg) translate(-20%, -55%);
    }
  }

  .slick-next {
    bottom: 0;

    &:before {
      transform: rotate(135deg) translate(-10%, 75%);
    }
  }

  .slick-disabled {
    opacity: .35;
  }
}

.product-card--main-image {
  display: flex;
  flex: 1;
  align-items: center;
  overflow: hidden;
  box-sizing: border-box;

  @media $mobile {
    padding-right: 30px;
  }

  .product-card--gallery + & {
    flex-basis: calc(100% - 85px);

    @media $mobile {
      flex-basis: calc(100% - 44px);
    }
  }

  .slick-slider {
    width: 100%;
  }

  .slick-slide {
    vertical-align: middle;
  }
}

.product-card--icons {
  @extend .product-icons-prototype;
  position: absolute;
  right: 0; top: 100px;

  @media $mobile {
    top: 50px;
  }
}

.product-card--caption-group {
  display: flex;
  justify-content: space-between;
}

.product-card--caption {
  @extend .h1;
  margin-bottom: 25px;

  @media $mobile {
    margin-bottom: 15px;
  }
}

.product-card--avail-row {
  display: flex;
  justify-content: space-between;

  .product-card--group {
    text-align: right;
  }

  @media $mobile {
    flex-wrap: wrap;

    .product-card--group {
      order: 1;
      width: 100%;
      border-bottom: 1px solid $gray;
      padding-bottom: 10px;
      margin-bottom: 25px;
    }
  }
}

.product-card--variants {
  display: flex;
  margin-bottom: 35px;

  @media $mobile {
    order: 2;
    margin-bottom: 25px;
  }

  .item {
    display: block;
    box-sizing: border-box;
    width: 25px; height: @width;
    text-decoration: none;
    overflow: hidden;
    border-radius: 50%;

    img {
      width: 100%; height: 100%;
      object-fit: cover;
    }

    &.active {
      border: 2px solid $turq !important;
    }
  }

  .item + .item {
    margin-left: 18px;
  }
}

.product-card--avail {
  font-size: 1.2rem;
  color: $gray;
}

.product-card--fav-btn {
  display: inline-block;
  font-size: 1.2rem;
  text-decoration: none;
  line-height: 23px;
  padding-left: 35px;
  background: url(/i/heart-icon.svg) no-repeat 0 50%;
  background-size: size('i/heart-icon.png');
  margin-left: 10px;

  &, &:hover {
    color: $gray;
  }

  @media $mobile {
    display: none;
  }

  &.active {
    background-image: url(/i/heart-icon-filled.svg);
  }
}

.product-card--delivery-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 10px;

  @media $not-mobile {
    border-bottom: 1px solid $gray;
  }
}

.product-card--delivery-caption {
  font-size: 1.9rem;
  font-weight: bold;
  font-variant: small-caps;
  text-transform: lowercase;

  @media $not-mobile {
    letter-spacing: 1px;
  }

  @media $mobile {
    1.4rem;
  }
}

.product-card--delivery-city {
  padding-right: 20px;
  position: relative;

  &:after {
    content: '';
    display: block;
    width: 5px; height: @width;
    border-bottom: 2px solid $black;
    border-left: 2px solid $black;
    position: absolute;
    top: 50%; right: 0;
    transform: rotate(-45deg) translateY(-60%);
  }
}

.product-card--article {
  white-space: nowrap;
  font-size: 1.4rem;
  font-weight: bold;
  letter-spacing: 1px;
  margin-left: 10px;
  margin-top: 1.1em;

  @media $tablet {
    margin-top: .8em;
  }

  @media $mobile {
    display: none;
  }
}

.product-card--delivery-content {
  padding-top: 30px;
  padding-bottom: 20px;

  @media $mobile {
    display: none;
  }

  p + p {
    margin-top: 1em;
  }

  img {
    vertical-align: middle;
    margin: -5px 0 0 5px;
  }
}

.product-card--buy-block {
  display: flex;
  justify-content: space-between;
  padding-top: 35px;

  @media $mobile {
    flex-wrap: wrap;
    padding-top: 10px;
  }
}

@media $not-mobile {
  .product-card--group_price {
    text-align: right;
  }
}

@media $mobile {
  .product-card--group_price {
    order: 1;
    width: 100%;
    margin-bottom: 40px;
  }

  .product-card--group_buttons {
    width: 128px;
    order: 2;
  }

  .product-card--group_amount {
    order: 3;
  }
}

.product-card--buy-btn {
  @extend .btn, .h40btn, .rounded-btn;
  width: 173px;

  &:not(.disabled) {
    @extend .turq-btn, .black-btn_hover;
  }

  &.disabled {
    @extend .black-btn;
  }

  @media $mobile {
    width: 128px;
  }

  &.is-fixed:not(.disabled) {
    width: 100%;
    position: fixed;
    left: 0; bottom: 0;
    z-index: 100;
    border-radius: 0;
    text-transform: uppercase;
    font-weight: bold;
  }
}

.product-card--fast-order-btn {
  display: inline-block;
  margin-top: 10px;
  font-size: 1.2rem;
  text-transform: lowercase;
  color: $gray;
}

.product-card--price {
  font-size: 3.2rem;
  font-weight: $semi-bold;
  font-variant: small-caps;
  text-transform: lowercase;

  .currency {
    font-weight: bold;
    font-size: 2.5rem;
  }

  @media $tablet {
    font-size: 2.8rem;

    .currency {
      font-size: 2rem;
    }
  }

  @media $mobile {
    font-size: 2.4rem;

    .currency {
      font-size: 1.8rem;
    }
  }
}

.product-card--old-price {
  display: inline-block;
  font-size: 2.4rem;
  font-weight: $light;
  color: $gray;
  margin-right: 54px;
  text-align: right;
  position: relative;
  top: -@font-size * 1.35;

  @media $mobile {
    font-size: 1.4rem;
    top: 0;
  }

  &:before {
    content: '';
    display: block;
    width: 100%; height: 1px;
    background: $gray;
    position: absolute;
    left: 0; top: 50%;
  }

  + .product-card--price {
    margin-top: -2.4rem * 1.35;

    @media $mobile {
      margin-top: 0;
    }
  }
}

.product-card--params {
  margin-bottom: 80px;

  @media $mobile {
    margin-bottom: 45px;

    &.is-collapsed {
      margin-bottom: 0;
    }
  }
}

.product-card--params-table {
  @extend .product-params-table-prototype;
}

@media $not-mobile {
  .product-card--responses {
    margin-bottom: 35px;
  }
}

.product-card--responses-hint {
  border-top: 1px solid $gray;
  padding-top: 30px;
  padding-left: 94px;
  padding-bottom: 30px;
  color: $gray;
  background: url(/i/responses-icon.png) no-repeat 0 50%;
  margin-bottom: 10px;

  @media $mobile {
    display: none;
  }
}

.product-card--sharing {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
  margin-top: 30px;

  @media $mobile {
    display: none;
  }
}

.product-card--sharing-caption {
  margin-right: 15px;
  margin-top: 5px;
  font-variant: small-caps;
  text-transform: lowercase;
  font-size: 1.8rem;
}

@media $mobile {
  .product-card--additional {
    margin-bottom: -11px;
  }
}

.bottom-catalogue-link {
  display: block;
  width: 100%; height: 80px;
  line-height: 70px;
  background: $peach;
  text-align: center;
  font-size: 1.9rem;
  font-weight: bold;
  font-variant: small-caps;
  text-transform: lowercase;
  text-decoration: none;
  transition: background-color .25s ease;
  position: absolute;
  left: 0; bottom: 0;

  &:hover {
    background: $dark-peach;
  }

  &:after {
    content: '';
    display: block;
    width: 6px; height: @width;
    border-bottom: 2px solid $black;
    border-left: 2px solid $black;
    position: absolute;
    left: 50%; top: 62%;
    margin-left: -3px;
    transform: rotate(-45deg);
    transition: opacity .25s ease;

    .is-expanded & {
      opacity: 0;
    }
  }

  &:before {
    content: '';
    display: block;
    width: 6px; height: @width;
    border-bottom: 2px solid $black;
    border-left: 2px solid $black;
    position: absolute;
    left: 50%; bottom: 58%;
    margin-left: -3px;
    transform: rotate(135deg);
    transition: opacity .25s ease;
    opacity: 0;

    .is-expanded & {
      opacity: 1;
    }
  }

  @media $mobile {
    display: none;
  }
}
