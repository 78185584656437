
/*   -=========== Layout ===========-   */

// Floats

.fl {
  float: left;
}

.fr {
  float: right;
}

.no-fl {
  float: none;
}

@media $not-small-mobile {
  .l-main {
    float: left;
    width: 49%;
  }

  .r-main {
    float: right;
    width: 49%;
  }
}

@media $small-mobile {
  .l-main + .r-main {
    margin-top: 10px;
  }
}


// Containers

.group, .nofloat {
  clearfix();
}

.no-overflow {
  overflow: hidden;
}

.flex-group {
  display: flex;

  &.justify {
    justify-content: space-between;
  }
}

.wrapper {
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  width: (200% / 3);

  @media $not-large-desktop {
    width: calc(100% - 40px);
  }
}

.wrapper_info {
  max-width: $text-wrapper;
}


// Grid

.row {
  display: flex;

  &:not(.row_equal-heights) {
    align-items: flex-start;
  }
}

.row_wrap {
  flex-wrap: wrap;
}

.row_justify {
  justify-content: space-between;
}

for $i in (1..12) {
  .column_{$i} {
    flex-basis: ($i * 100% / 12);
  }

  .column_offset-{$i} {
    margin-left: ($i * 100% / 12);
  }
}

@media $not-large-desktop {
  for $i in (1..12) {
    .column_xl-{$i} {
      flex-basis: ($i * 100% / 12);
    }
  }
}

@media $notebook {
  for $i in (1..12) {
    .column_md-{$i} {
      flex-basis: ($i * 100% / 12);
    }
  }
}

@media $tablet {
  for $i in (1..12) {
    .column_sm-{$i} {
      flex-basis: ($i * 100% / 12);
    }
  }
}

@media $mobile {
  for $i in (1..12) {
    .column_xs-{$i} {
      flex-basis: ($i * 100% / 12);
    }
  }
}


// Margins

.m3 {
  margin-bottom: 3px;
}

.m7 {
  margin-bottom: 7px;
}

for $i in (0..20) {
  .m{$i * 5} {
    margin-bottom: 5px * $i;
  }
}


// Position

.relative {
  position: relative;
}

.static {
  position: static !important;
}


// Display

.inline {
  display: inline;
}

.inline-block {
  display: inline-block;
}

.block {
  display: block;
}

.hidden {
  display: none !important;
}


// Devices display

@media $handheld {
  .only-desktop {
    display: none !important;
  }
}

@media $not-mobile {
  .only-mobile {
    display: none !important;
  }
}

@media $desktop {
  .not-desktop { // mobile + tablet
    display: none !important;
  }
}

@media $mobile {
  .not-mobile { // desktop + tablet
    display: none !important;
  }
}

@media $small-handheld {
  .not-small-handheld {
    display: none !important;
  }
}

@media $not-small-handheld {
  .only-small-handheld {
    display: none !important;
  }
}
