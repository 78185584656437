
/*   -=========== Override vendor styles ===========-   */

.ui-autocomplete {
  z-index: 10;
  max-height: 431px;
  overflow: auto;
  background: $white;
  list-style: none;
  word-wrap: break-word;
  box-shadow: 0 3px 15px rgba($black, .2);
  padding-top: 10px;
  padding-bottom: 20px;
}
.ui-menu {
  .ui-menu-item {
    padding: 0;
    > a {
      display: block;
      color: $gray;
    }
    &:hover {
      z-index: 10;
    }
  }
}
.autocomplete-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 40px;
  background: white;
  font-size: 1.2rem;
  cursor: pointer;
  &:hover {
    color: $black;
    z-index: 10;
  }
  span {
    display: inline-block;
    max-width: 100%;
  }
}
.autocomplete-pic {
  float: left;
  width: 75px;
  height: 75px;
  text-align: center;
  > img {
    max-width: 100%;
    max-height: 100%;
  }
}
.autocomplete-content {
  display: block;
  font-size: 12px;
  line-height: 120%;
  .autocomplete-pic + & {
    margin-left: 85px;
  }
}
.autocomplete-price {
  margin-left: 15px;
}

.ui-helper-hidden-accessible {
  display: none;
}


// Yashare

.ya-share2__item {
  display: inline-block;
  vertical-align: middle;
  list-style: none;
}

.ya-share2__item + .ya-share2__item {
  margin-left: 20px;
}

.ya-share2__title {
  font-size: 0;
}

.ya-share2__badge {
  display: inline-block;
  vertical-align: middle;
}

.ya-share2__item_service_vkontakte .ya-share2__badge:before {
  content: url(/i/sm-vk.png);
}

.ya-share2__item_service_facebook .ya-share2__badge:before {
  content: url(/i/sm-fb.png);
}

.ya-share2__item_service_odnoklassniki .ya-share2__badge:before {
  content: url(/i/sm-ok.png);
}

.ya-share2__item_service_gplus .ya-share2__badge:before {
  content: url(/i/sm-gplus.png);
}

.ya-share2__item_service_twitter .ya-share2__badge:before {
  content: url(/i/sm-twit.png);
}


// Selectric

.selectric {
  border: 2px solid $black !important;
  background: $white;
  border-radius: 20px;

  .label {
    height: 36px;
    line-height: @height;
    margin: 0 40px 0 20px;
    font-size: 1.2rem;
  }

  .button {
    font-size: 0;
    position: absolute;
    width: 20px; height: @width;
    right: 10px; top: 50%;
    transform: translateY(-50%);

    &:before, &:after {
      content: '';
      display: block;
      width: 7px; height: 2px;
      background: $black;
      position: absolute;
      bottom: 50%;
      border: none;
      top: auto;
      margin-bottom: -3px;
    }

    &:before {
      right: 50%; left: auto;
      transform: rotate(45deg);
      transform-origin: right bottom;
    }

    &:after {
      left: 50%; right: auto;
      transform: rotate(-45deg);
      transform-origin: left bottom;
    }
  }
}

.selectric-items {
  font-size: 1.2rem;
  border-radius: 20px;

  li {
    padding-left: 20px;
    padding-right: 20px;
  }

  li:first-child {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }

  li:last-child {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
}


// alertify

.alertify {
  border: 2px solid $black;
  width: 250px;
  margin-left: 0;
  top: 50%;
  transform: translate(-50%, -50%);
  background: $white;
}

.alertify-message {
  margin-bottom: 15px;
}

.alertify-button {
  display: inline-block;
  margin-left: 0 !important;
  line-height: 30px !important;
  width: 40px;
}

.alertify-button-ok {
  background: $black;
  color: $white;
  box-shadow: 0 0 0 2px $black;

  &:hover {
    background: $white;
    color: $black;
  }
}

.alertify-button-cancel {
  background: $white;
  box-shadow: 0 0 0 2px $black;
  margin-left: 10px !important;

  &:hover {
    background: $black;
    color: $white;
  }
}
