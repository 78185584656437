
/*   -=========== Buttons ===========-   */

.btn {
  display: inline-block;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  user-select: none;
  transition: all .25s ease;
}

// use box-shadow instead of border to preserve button line-height
bordered-btn($border, $color) {
  box-shadow: inset 0 0 0 $border $color;
}

.black-bordered-btn {
  color: $black;
  bordered-btn(1px, $black);

  &:hover {
    color: $black;
  }
}

.black-btn {
  background: $black;
  color: $white;
}

.white-bordered-btn {
  color: $white;
  bordered-btn(1px, $white);

  &:hover {
    color: $white;
  }
}

.white-btn {
  background: $white;
  color: $black;
}

.turq-btn {
  background: $turq;
  color: $black;
}

.peach-btn {
  background: $peach;
  color: $black;
}

.black-btn_hover {
  &:hover {
    background: $black;
    color: $white;
  }
}

.peach-btn_hover {
  &:hover {
    background: $peach;
    color: $black;
  }
}

.rounded-btn {
  border-radius: 19px;
}


// Sizes

.wide-btn {
  width: 100%;
}

.h40btn {
  line-height: 40px;
}

.r20btn {
  border-radius: 20px;
}
