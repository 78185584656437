
/*   -=========== Forms ===========-   */

// Base

input,
textarea {
  font: inherit;
}

.form {
  span.required {
    color: $warning-color;
  }
}

.inp,
.textarea {
  box-sizing: border-box;
  width: 100%;
  padding: .3125em;
  line-height: 1.25em;
  color: lighten($text-color, 5);
  border: 1px solid lighten($black, 5);
  transition: all .25s ease;

  &:focus {
    color: $text-color;
    border-color: $black;
  }

  .form-field.error > & {
    border-color: $warning-color;
    color: $warning-color;
  }
}

.spinner {
  display: inline-block;
  padding-left: 30px;
  padding-right: 30px;
  position: relative;

  .spinner-down,
  .spinner-up {
    display: block;
    width: 25px; height: 100%;
    position: absolute;
    top: 0;
    font-size: 2.4rem;
    background: transparent;
  }

  .spinner-down {
    left: 0;
    font-size: 0;

    &:before {
      content: '';
      display: block;
      width: 11px; height: 2px;
      background: $black;
      position: absolute;
      left: 50%; top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .spinner-up {
    right: 0;
  }

  .inp {
    width: 67px; height: 40px;
    box-sizing: border-box;
    line-height: 36px;
    border: 2px solid $black;
    border-radius: 19px;
    text-align: center;
    font-size: 1.4rem;
    font-weight: bold;
  }
}

.radio {
  padding-left: 40px;
  padding-top: .1em;
  position: relative;
  color: $gray;

  &:not(.checked) {
    cursor: pointer;
  }

  &:before {
    content: '';
    display: block;
    width: 20px; height: 20px;
    border-radius: 50%;
    box-sizing: border-box;
    border: 2px solid $gray;
    position: absolute;
    left: 0; top: 0;
    transition: border-color .25s ease;
  }

  &:after {
    content: '';
    display: block;
    width: 10px; height: 10px;
    background: $black;
    border-radius: 50%;
    opacity: 0;
    transition: opacity .25s ease;
    position: absolute;
    left: 5px; top: 5px;
  }

  &.checked {
    color: $black;

    &:before {
      border-color: $black;
    }

    &:after {
      opacity: 1;
    }
  }

  input {
    display: none;
  }
}

.form-row {
  clearfix();

  + .form-row {
    .one-column-form & {
      margin-top: .5em;
    }

    .two-columns-form & {
      margin-top: 1.25em;
    }
  }
}

.form-label {
  .one-column-form & {
    margin-bottom: .15em;
  }

  .two-columns-form & {
    float: left;
  }
}

.form-field {
  position: relative;

  .two-columns-form & {
    float: right;
  }

  .errorMessage {
    display: none;
    font-size: 1.2rem;
    line-height: 1em;
    padding: 5px 0 3px 40px;
    color: $warning-color;

    .two-columns-form & {
      position: absolute
      left: 0; bottom: 0;
      transform: translateY(100%);
      width: 100%;
    }
  }

  &.error {
    margin-bottom: .25em;

    .errorMessage {
      display: block;
    }
  }
}

.form-submit {
  .two-columns-form & {
    float: right;
  }

  .submit-button {
    @extend .btn, .turq-btn, .black-btn_hover;
    line-height: 54px;
    padding: 0 55px;
    border-radius: 24px;
  }
}

.form-field .inp {
  border: none;
  background: $almost-white;
  border-radius: 25px;
  line-height: 54px;
  padding: 0 40px;
  font-size: 1.4rem;
}

.form-hint {
  font-size: 1.2rem;
  margin-top: 20px;

  &, a {
    color: $gray;
  }
}


// Order form
// ----------

.order-form {
  .fieldset + .fieldset {
    margin-top: 50px;
  }

  .fieldset--caption {
    font-size: 1.9rem;
    font-weight: bold;
    font-variant: small-caps;
    text-transform: lowercase;
    letter-spacing: 1px;
    padding: 10px 0;
    border-bottom: 1px solid $gray;
    margin-bottom: 30px;
  }

  .fieldset:first-child .fieldset--caption {
    border-bottom: none;
    padding-bottom: 0;
  }

  .radio-input + .radio-input {
    margin-top: 10px;
  }

  .form-row + .form-row {
    margin-top: 25px;
  }

  .form-field {
    max-width: 572px;
  }
}

.form-field_delivery-distance {
  display: flex;
  align-items: center;

  @media $not-mobile {
    padding-left: 40px;
  }

  .inp {
    max-width: 100px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    margin-left: 15px;
    margin-right: 15px;
  }
}

.form-field_pickpoint {
  margin-top: 15px;

  @media $not-mobile {
    padding-left: 40px;
    display: flex;
    align-items: center;

    label {
      margin-right: 15px;
    }
  }

  @media $mobile {
    label {
      margin-bottom: 10px;
    }

    .selectric .label {
      max-width: calc(100vw - 100px);
    }
  }
}


// Callback form
// -------------

.callback-form {
  .form-row + .form-row {
    margin-top: 27px;
  }

  .form-submit {
    margin-top: 45px;

    @media $mobile {
      text-align: center;
    }
  }
}


// Fast order form
// ---------------

.fast-order-form {
  width: 560px;

  @media $portrait-tablet {
    width: 320px;
  }

  @media $mobile {
    width: 280px;
  }

  .form-row + .form-row {
    margin-top: 27px;
  }

  .form-submit {
    margin-top: 45px;
  }
}
