
/*   -=========== Info ===========-   */

.info-page {
  margin-bottom: 100px;

  @media $small-handheld {
    padding-top: 30px;
  }
}

.info-page--caption {
  @extend .h1;
  text-transform: uppercase;
  padding-bottom: 40px;
  margin-bottom: 40px;
  position: relative;

  @media $mobile {
    padding-bottom: 25px;
    margin-bottom: 25px;
  }

  &:after {
    content: '';
    display: block;
    width: 31px; height: 2px;
    background: $black;
    position: absolute;
    left: 0; top: 100%;
  }
}

.info-page .text-container {
  line-height: 1.75;
}
