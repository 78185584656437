
/*   -=========== Tabs ===========-   */

.tabs-nav {
  display: flex;
  justify-content: center;

  li {
    list-style: none;
  }

  a {
    display: block;
    text-decoration: none;
  }
}

.tabs-panel {
  display: none;
}

.tabs_default {
  .tabs-nav {
    background: $dark-turq;

    li + li {
      margin-left: 2vw;
    }

    li a {
      line-height: 57px;
      border-bottom: 3px solid transparent;
      transition: border-bottom-color .25s ease;
      padding: 0 20px;
      box-sizing: border-box;
      min-width: 152px;
      text-align: center;
      font-size: 1.9rem;
      font-weight: bold;
      font-variant: small-caps;
      text-transform: lowercase;
    }

    .is-active a {
      border-bottom-color: $black;
    }
  }
}
