
/*   -=========== Imports ===========-   */

/* @import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&subset=cyrillic'); */

@import "/i/style/vendor/pswp/photoswipe.css";
@import "/i/style/vendor/pswp/default-skin.css";

@import "_variables";
@import "_utils";

@import "base/_fonts";

@import "vendor/_alertify";
@import "vendor/_selectric";
@import "vendor/_perfect-scrollbar";
@import "vendor/_jquery.tosrus.all";
@import "vendor/_jquery.uniloader";
@import "vendor/_slick";

@import "_overrides"; // override vendor styles

@import "base/_resets";
@import "base/_typography";
@import "base/_layout";
@import "base/_print";

@import "components/_common";
@import "components/_buttons";
@import "components/_tabs";
@import "components/_forms";
@import "components/_menus";
@import "components/_popups";
@import "components/_carousels";
@import "components/_spoilers";
@import "components/_vitrine";
@import "components/_filter";
@import "components/_actions-rotator";
// @import "components/_instagram";

@import "pages/_homepage";
@import "pages/_catalogue";
@import "pages/_card";
@import "pages/_contacts";
@import "pages/_order";
@import "pages/_brands";
@import "pages/_info";
@import "pages/_errors";

@import "_structure";
