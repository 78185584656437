
/*   -=========== Popups ===========-   */

// Base

.popup {
  display: none;
  position: absolute;
  background: $body-bg;
  z-index: 333;
  box-shadow: 0 0 20px rgba($black, .16);
}

.popup--close {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0; right: 0;
  cursor: pointer;
  background: transparent;

  &:before, &:after {
    content: '';
    display: block;
    width: 22px; height: 3px;
    position: absolute;
    top: 50%;
    background: $black;
  }

  &:before {
    left: 50%;
    transform: rotate(-45deg) translate(-50%, -50%);
    transform-origin: left top;
  }

  &:after {
    right: 50%;
    transform: rotate(45deg) translate(50%, -50%);
    transform-origin: right top;
  }
}


// City popup

.city-popup {
  box-sizing: border-box;
  max-width: 1280px;
  padding: 55px 90px 100px;
  width: 100%;

  .popup--close {
    top: 56px; right: 78px;
  }

  @media $mobile {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 50px;

    .popup--close {
      top: 5px; right: 5px;
    }
  }
}

.city-popup--caption {
  @extend .h1;
  text-transform: lowercase;
  margin-bottom: 40px;
}

.city-popup--form {
  margin-bottom: 60px;
  position: relative;

  @media $mobile {
    text-align: center;
    margin-bottom: 30px;
  }

  .inp {
    line-height: 54px;
    padding: 0 250px 0 35px
    font-size: 1.4rem;
    border: none;
    background: $almost-white;
    border-radius: 25px;

    @media $mobile {
      padding: 0 20px;
    }

    &::placeholder {
      opacity: 1;
      color: $black;
    }
  }

  .btn {
    @extend .turq-btn, .black-btn_hover;
    width: 236px;
    line-height: 54px;
    border-radius: 25px;

    @media $not-mobile {
      position: absolute;
      right: 0; top: 0;
    }

    @media $mobile {
      margin-top: 10px;
    }
  }

  .suggestions-addon[data-addon-type=clear] {
    display: none !important;
  }
}

.city-popup--cities-list {
  font-size: 1.4rem;
  font-weight: bold;
  max-width: 760px;

  @media $not-mobile {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }

  @media $mobile {
    height: auto !important;
  }

  .item {
    box-sizing: border-box;
    margin-bottom: 7px;
    margin-top: 7px;
    padding-right: 3vw;
    cursor: pointer;

    @media $not-mobile {
      width: 33.33%;
    }
  }
}


// Callback popup

.callback-popup {
  padding: 55px 80px;
  box-sizing: border-box;
  max-width: 100%;
  width: 725px;

  .popup--close {
    right: 75px; top: 55px;
  }

  @media $mobile {
    padding: 30px 20px;

    .popup--close {
      top: 26px; right: 12px;
    }
  }
}

.callback-popup--caption {
  @extend .h1;
  text-transform: lowercase;
  margin-bottom: 40px;
}


// Fast order popup

.fast-order-popup {
  padding: 50px 85px 70px 70px;

  .popup--close {
    right: 80px; top: 50px;
  }

  @media $mobile {
    padding: 30px 20px;

    .popup--close {
      top: 25px; right: 10px;
    }
  }
}

.fast-order-popup--layout {
  display: flex;
  align-items: center;
}

.fast-order-popup--info {
  max-width: 160px;
  margin-right: 75px;
  text-align: center;

  @media $mobile {
    display: none;
  }
}

.fast-order-popup--pic {
  margin-bottom: 30px;
}

.fast-order-popup--name {
  font-size: 1.4rem;
  font-weight: bold;

  a {
    text-decoration: none;
  }
}

.fast-order-popup--caption {
  @extend .h1;
  text-transform: lowercase;
  margin-bottom: 45px;
}
