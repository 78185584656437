
/*   -=========== Structure ===========-   */

// Base

body.mobile-menu-is-opened {
  overflow: hidden;
  height: 100vh;
}

#structure {
  margin: auto;
  position: relative;
}

@media $small-handheld {
  #content {
    padding-top: $mobile-header-height;
  }
}

/* @media $portrait-tablet {
  #content {
    padding-top: $mobile-header-height + 20px;
  }
} */

.footer {
  position: relative;
  background: $black;
  padding-top: 90px;
  font-size: 1.6rem;

  @media $handheld {
    font-size: 1.4rem;
  }

  @media $small-handheld {
    padding-top: 30px;
  }

  &, a {
    color: $white;
  }
}


// Header

.header {
  align-items: center;
  font-size: 1.2rem;

  @media $not-mobile {
    border-bottom: 1px solid $gray;
  }

  @media $not-large-desktop {
    @media $not-small-handheld {
      padding-right: 20px;
    }
  }

  @media $small-handheld {
    width: 100%; height: $mobile-header-height;
    line-height: @height;
    background: $white;
    position: fixed;
    left: 0; top: 0;
    z-index: 1000;
  }

  .mobile-menu-is-opened & {
    border-bottom: 1px solid $light-gray;
  }
}

.header--mobile-menu-trigger {
  @media $not-small-handheld {
    display: none;
  }

  @media $small-handheld {
    display: block;
    width: 40px; height: 40px;
    background: transparent;
    position: absolute;
    left: 12px; top: 50%;
    transform: translateY(-50%);

    .bars {
      left: 50%;
      transform: translate(-50%, -50%);

      &, &:before, &:after {
        display: block;
        width: 18px; height: 2px;
        position: absolute;
        background: $gray;
        transition: all .25s ease;
      }

      &:before, &:after {
        content: '';
        left: 0;
      }

      &:before {
        bottom: 5px;
      }

      &:after {
        top: 5px;
      }
    }

    .mobile-menu-is-opened & {
      .bars {
        background: rgba($black, 0);

        &:before, &:after {
          background: $black;
          margin-left: -4px;
        }

        &:before {
          transform: rotate(-45deg);
          transform-origin: right top;
          margin-bottom: 1px;
        }

        &:after {
          transform: rotate(45deg);
          transform-origin: right bottom;
          margin-top: 1px;
        }
      }
    }
  }
}

.header--logo-column {
  text-align: center;

  @media $tablet {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.header--logo {
  display: inline-block;
  vertical-align: middle;
  width: width('i/prouyut-logo.png'); height: height('i/prouyut-logo.png');
  background: url(/i/prouyut-logo.svg) no-repeat 50%;
  font-size: 0;
  text-decoration: none;

  @media $small-handheld {
    background: url(/i/prouyut-logo-horizontal.svg) no-repeat 50% / contain;
    width: 90px; height: 35px;
    position: absolute;
    left: 50%; top: 50%;
    transform: translate(-50%, -50%);
  }
}

.header--slogan {
  font-size: 1.2rem;
  margin-top: 10px;

  @media $small-handheld {
    display: none;
  }
}

@media $not-small-handheld {
  .header--line {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & + & {
      border-top: 1px solid $light-gray;
    }
  }
}

.header--line_1 {
  line-height: 76px;
  position: relative;
}

.header--callback-link {
  display: inline-block;
  line-height: 1.2;
  padding-left: 38px;
  font-weight: bold;
  background: url(/i/callback-icon.svg) no-repeat 0 50%;
  background-size: size('i/callback-icon.svg');

  &, &:hover {
    color: $gray;
  }

  @media $small-handheld {
    font-size: 0;
    width: 40px; height: @width;
    background-position: 50%;
    padding: 0;
    position: absolute;
    top: 50%; right: 55px;
    transform: translateY(-50%);
  }
}

.header--phones {
  line-height: 1.2;

  a {
    color: $gray;
    font-weight: bold;
  }

  @media $small-handheld {
    display: none;
    position: absolute;
    background: $white;
    padding: 20px;
    z-index: 999;
    text-align: right;

    a {
      font-size: 2rem;
      color: $black;
    }

    .item + .item {
      margin-top: .5em;
    }
  }
}

.header--worktime {
  line-height: 1.2;
  font-weight: bold;
  color: $gray;
  margin-right: 10%;

  @media $small-handheld {
    display: none;
  }
}

.header--link-with-dropdown {
  position: relative;

  &:after {
    content: '';
    display: block;
    width: 100%; height: 3px;
    background: $black;
    position: absolute;
    left: 50%; bottom: -1px;
    transform: translateX(-50%);
    opacity: 0;
    max-width: 0;
    transition: all .25s ease;
  }

  .dropdown {
    display: none;
    position: absolute;
    left: -35px; top: 100%;
    margin-top: 1px;
    z-index: 100;
    background: $white;
    padding-top: 25px;
    padding-bottom: 35px;
    box-shadow: 0 5px 20px rgba($black, .15);

    li {
      line-height: 1.2;
      list-style: none;
    }

    li a {
      display: block;
      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 35px;
      padding-right: 65px;
      white-space: nowrap;
      text-decoration: none;
    }

    a:hover {
      color: $gray;
    }
  }

  &:hover:after {
    opacity: 1;
    max-width: 140px;
  }

  &:hover .dropdown {
    display: block;
  }
}

.header--link {
  text-decoration: none;

  @media $small-handheld {
    display: none;
  }
}

.header--city {
  background: url(/i/geolocation-icon.png) no-repeat 0 50%;
  background-size: size('i/geolocation-icon.png');
  padding-left: 40px;

  &, &:hover {
    color: $gray;
  }

  @media $small-handheld {
    display: none;
  }
}

.city-confirm-popup {
  display: none;
  position: absolute;
  top: 67px;
  z-index: 100;
  margin-left: 12px;
  background: $white;
  box-shadow: 0 -5px 30px rgba($black, .12);
  padding: 40px 35px 37px;
  text-align: center;
  line-height: 1.2;

  &:before {
    content: '';
    display: block;
    background: $white;
    width: 14px; height: @width;
    position: absolute;
    left: 50%; bottom: 100%;
    transform: rotate(-45deg);
    transform-origin: right center;
    margin-left: -10px;
    margin-bottom: -2px;

    @media $portrait-tablet {
      display: none;
    }
  }

  @media $not-large-desktop {
    right: 0; left: auto !important;

    &:before {
      left: auto; right: 30px;
      margin-left: 0;
    }
  }
}

.city-confirm-popup--text {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.city-confirm-popup--city {
  font-size: 1.7rem;
  font-weight: bold;
  margin-bottom: 30px;
}

.city-confirm-popup--controls {
  white-space: nowrap;
}

.city-confirm-popup--accept-button,
.city-confirm-popup--change-button {
  @extend .btn;
  width: 128px;
  line-height: 35px;
  font-size: 1.2rem;
  border-radius: 17px;
}

.city-confirm-popup--accept-button {
  @extend .turq-btn;

  &:hover {
    background: darken($turq, 8);
  }
}

.city-confirm-popup--change-button {
  bordered-btn(2px, $black);
  background: $white;
  margin-left: 15px;
  @extend .black-btn_hover;
}

@media $small-handheld {
  .header--line_2 {
    display: none;
  }
}

.header--line_3 {
  line-height: 66px;
}

.header--search {
  position: relative;

  @media $desktop {
    /* margin-right: 25%; */
    width: 70%;
  }

  /* @media $small-notebook {
    margin-right: 0;
  } */

  @media $small-handheld {
    display: none;
  }

  .inp {
    font-size: 1.4rem;
    padding-left: 64px;
    border: none;
    box-sizing: border-box;

    @media $small-notebook {
      width: 475px;
    }

    @media $tablet {
      width: 475px;
    }

    &::placeholder {
      opacity: 1;
      color: $gray;
    }
  }

  .btn {
    position: absolute;
    left: 0; top: 50%;
    transform: translateY(-50%);
    width: 40px; height: @width;
    border-radius: 50%;
    background: $turq url(/i/search-icon-white.svg) no-repeat 50% / 21px auto;
    font-size: 0;
  }
}

.header--favorites {
  display: block;
  padding-left: 40px;
  background: url(/i/heart-icon.svg) no-repeat 0 50%;
  background-size: size('i/heart-icon.png');
  color: $gray;
  text-decoration: none;
  position: relative;

  @media $small-handheld {
    display: none;
  }

  &.is-filled {
    padding-left: 30px;
    background-image: url(/i/heart-icon-filled.svg);

    &, .header--favorites-counter:after {
      color: $black;
    }

    @media $not-small-handheld {
      &:before {
        content: '';
        display: block;
        width: calc(100% + 20px); height: 35px;
        border-radius: 20px;
        background: $turq;
        position: absolute;
        left: 50%; top: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
      }
    }
  }
}

.header--basket {
  margin-left: 20px;
  color: $gray;
  padding-left: 42px;
  background: url(/i/basket-icon.svg) no-repeat 0 50%;
  background-size: size('i/basket-icon.svg');
  position: relative;

  &.is-filled {
    background-image: url(/i/basket-icon-filled.svg);

    &, .header--basket-counter:after {
      color: $black;
    }

    @media $not-small-handheld {
     padding-left: 30px;

      &:before {
        content: '';
        display: block;
        width: calc(100% + 20px); height: 35px;
        border-radius: 20px;
        background: $turq;
        position: absolute;
        left: 50%; top: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
      }
    }
  }

  a {
    text-decoration: none;

    @media $small-handheld {
      display: block;
      width: 100%; height: 100%;
    }
  }

  @media $small-handheld {
    font-size: 0;
    width: 40px; height: @width;
    line-height: 0;
    background-position: 50%;
    padding: 0;
    position: absolute;
    top: 50%; right: 10px;
    transform: translateY(-50%);

    > div {
      height: 100%;
    }
  }
}

.header--favorites-counter,
.header--basket-counter {
  color: $black;
  font-weight: bold;

  &:after {
    content: '/';
    display: inline-block;
    color: $gray;
    font-weight: normal;
    margin-left: 3px;
    margin-right: 3px;
  }

  @media $small-handheld {
    position: absolute;
    right: -5px; top: -2px;
    font-size: 1rem;
    background: $white;
    border-radius: 50%;
    width: 12px; height: @width;
    line-height: @width;

    &:after {
      display: none;
    }
  }
}

.header--favorites,
.header--basket {
  &:hover {
    [class$='-counter']:after {
      color: $black;
    }
  }
}


// Footer

.footer {
  .row {
    align-items: stretch;
  }

  .column--caption {
    font-size: 3rem;
    font-weight: bold;
    text-transform: lowercase;
    padding-bottom: 25px;
    position: relative;
    margin-bottom: 35px;

    @media $tablet {
      font-size: 2rem;
    }

    @media $small-handheld {
      font-size: 1.8rem;
    }

    &:after {
      content: '';
      display: block;
      width: 31px; height: 2px;
      background: $white;
      position: absolute;
      left: 0; top: 100%;

      @media $portrait-tablet {
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  @media $small-handheld {
    .row {
      flex-direction: column;
    }

    .column {
      width: 100%;
    }

    .column + .column {
      margin-top: 45px;
    }

    .menu-column + .menu-column {
      margin-top: 1.5em;
    }

    .info-menu {
      display: none;
    }

    .info-menu-link {
      bordered-btn(2px, $white);
      width: 100%;
      height: 56px;
      line-height: 52px;
      padding: 0;
      text-align: center;

      &:after {
        display: none;
      }

      &:before {
        content: '';
        display: block;
        width: 7px; height: 7px;
        border-right: 2px solid $white;
        border-top: 2px solid $white;
        position: absolute;
        left: 50%; top: 50%;
        margin-left: 96px;
        margin-top: 2px;
        transform: rotate(45deg) translate(-50%, -50%);
      }

      &.is-active:before {
        transform: rotate(135deg) translate(-50%, -50%);
        margin-top: -5px;
      }
    }
  }

  @media $portrait-tablet {
    text-align: center;
  }
}

.footer--copyright {
  border-top: 1px solid $white;
  padding-top: 25px;
  padding-bottom: 25px;
  font-size: 1.6rem;
  letter-spacing: 1px;
  margin-top: 50px;

  &, a {
    color: $gray;
  }

  a {
    text-decoration: none;

    img {
      vertical-align: middle;
      margin-top: -6px;
    }
  }

  @media $small-handheld {
    font-size: 1.2rem;

    .column + .column {
      margin-top: .5em;
    }
  }
}

.footer--contact {
  letter-spacing: 1px;

  p + p:not(.no-margin) {
    margin-top: 1.5em;
  }
}

.footer--socials {
  a {
    display: inline-block;

    &:before {
      content: '';
      display: block;
      background-position: 50%;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  a + a {
    margin-left: 15px;
  }

  for $social in 'vk' 'fb' 'twit' 'gplus' 'ok' {
    .sm-{$social}:before {
      width: width('i/sm-' + $social + '-white.png');
      height: height('i/sm-' + $social + '-white.png');
      background-image: url('/i/sm-' + $social + '-white.png');

      @media $small-handheld {
        width: width('i/sm-' + $social + '-white.png', 1.5);
        height: height('i/sm-' + $social + '-white.png', 1.5);
      }
    }
  }
}

.footer--subscribe {
  position: relative;
  padding-right: 57px;

  @media $large-desktop {
    margin-right: -@padding-right;
  }

  .inp {
    border: 3px solid $white;
    padding: 0 25px;
    line-height: 50px;
    background: $black;
    color: $white;
    font-size: 1.6rem;
  }

  .btn {
    width: width('i/submit-arrow.png'); height: height('i/submit-arrow.png');
    background: url(/i/submit-arrow.svg) no-repeat 50%;
    background-size: contain;
    font-size: 0;
    position: absolute;
    right: 0; top: 50%;
    transform: translateY(-40%);
  }
}

.footer--subscribe-hint {
  font-size: 1.2rem;
  color: $gray;
  margin-top: 15px;
  margin-bottom: 35px;
}

.footer--payment-row {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;

  a {
    text-decoration: none;
  }

  @media $large-desktop {
    margin-right: -57px;
  }

  @media $handheld {
    flex-direction: column;

    .footer--group + .footer--group {
      margin-top: 20px;
    }
  }

  @media $small-notebook {
    flex-direction: column;

    .footer--group + .footer--group {
      margin-top: 20px;
    }
  }
}

.footer--payment-row-caption {
  letter-spacing: 1px;
  margin-bottom: 25px;
}

.footer--payment img + img {
  margin-left: 10px;
}

.footer--rating {
  height: 37px;
  line-height: @height;
  background: $white;
}

.go-top-btn {
  @extend .btn;
  width: 40px; height: @width;
  position: fixed;
  left: 15px; bottom: 15px;
  z-index: 1000;
  overflow: hidden;
  max-height: 0
  opacity: 0;
  background: $peach;

  &:before {
    content: '';
    display: block;
    width: 18px; height: 18px;
    border-top: 2px solid $black;
    border-right: 2px solid $black;
    position: absolute;
    left: 50%; top: 50%;
    transform: rotate(-45deg) translate(-50%, -50%);
    transform-origin: center top;
    margin-left: -3px;
  }

  &.is-visible {
    opacity: 1;
    max-height: @width;
  }
}


// Rotation warning

.rotate-device-warning {
  display: none;
  background: $black url(/i/rotate-device.png) no-repeat 50% 40%;
  width: 100%; height: 100%;
  position: fixed;
  left: 0; top: 0;
  z-index: 10000;
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: bold;
  color: $white;
  text-align: center;

  span {
    position: absolute;
    left: 50%; bottom: 20px;
    transform: translateX(-50%);
    white-space: nowrap;
  }

  @media only screen and (max-device-width: 736px) and (min-device-width: 480px) and (orientation: landscape) {
    display: block;
  }
}

@media only screen and (max-device-width: 736px) and (min-device-width: 480px) and (orientation: landscape) {
  body {
    height: 100vh;
    overflow: hidden;

    &:after {
      content: '';
      display: block;
      width: 100%; height: 200%;
      background: $black;
      position: fixed;
      left: 0; top: 0;
    }
  }
}
