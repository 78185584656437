
/*   -=========== Spoilers ===========-   */

.spoiler--header {
  padding-bottom: 12px;
  border-bottom: 1px solid $gray;
  font-size: 1.9rem;
  font-weight: bold;
  font-variant: small-caps;
  text-transform: lowercase;
  letter-spacing: 1px;
  padding-right: 20px;
  position: relative;

  &.js-spoiler-anchor {
    cursor: pointer;
  }

  .icon {
    display: block;
    width: 11px; height: 9px;
    position: absolute;
    right: 0; top: 50%;
    transform: translateY(-60%);
    transition: all .25s ease;

    &:before {
      content: '';
      display: block;
      width: 5px; height: @width;
      border-top: 2px solid $black;
      border-right: 2px solid $black;
      transform: rotate(-45deg) translate(-20%, -60%);
      position: absolute;
      left: 50%; top: 50%;
    }

    .spoiler.is-collapsed & {
      transform: rotate(90deg) translate(-70%, 0);
    }

    @media $mobile {
      .spoiler.is-collapsed-on-mobile & {
        transform: rotate(90deg) translate(-70%, 0);
      }
    }
  }
}

.spoiler--body {
  overflow: hidden;
  transition: all .25s ease;

  .spoiler:not(.is-collapsed):not(.is-collapsed-on-mobile) & {
    padding-top: 30px;
    max-height: 5000px;
    opacity: 1;

    @media $mobile {
      padding-top: 15px;
      margin-bottom: 35px;
    }
  }

  @media $not-mobile {
    .spoiler:not(.is-collapsed) & {
      padding-top: 30px;
    }

    .spoiler.spoiler_mobile & {
      padding-top: 0;
    }
  }

  .spoiler.is-collapsed & {
    max-height: 0;
    opacity: 0;

    @media $mobile {
      margin-bottom: 10px;
    }
  }

  @media $mobile {
    .spoiler.is-collapsed-on-mobile & {
      max-height: 0;
      opacity: 0;
      margin-bottom: 10px;
    }
  }
}
