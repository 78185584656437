
/*   -=========== Filter ===========-   */

.filter {
  flex-shrink: 0;
  background: $peach;
  font-size: 1.4rem;
  overflow: hidden;
  position: relative;

  @media $desktop {
    flex-basis: (100% / 6);
  }

  @media $notebook {
    flex-basis: (100% / 5);
  }

  @media $landscape-tablet {
    flex-basis: (100% / 4);
  }

  @media $small-handheld {
    display: none;
    position: fixed;
    z-index: 1000;
    width: 320px;
    left: 0 !important;
    top: $mobile-header-height;
    height: 'calc(100vh - %s)' % $mobile-header-height !important;
    margin-bottom: 0 !important;
    overflow: visible;
    box-shadow: 0 5px 20px rgba($black, .15);
  }

  @media $small-mobile {
    width: 100%;
  }

  .ps-scrollbar-y-rail {
    opacity: 1 !important;
    background: $dark-peach !important;
    width: 3px;
    border-radius: 2px;
    right: 13px;
    cursor: pointer;
  }

  .ps-container > .ps-scrollbar-y-rail > .ps-scrollbar-y {
    width: 3px;
    border-radius: 2px;
    background: $black !important;
  }

  .submit-btn {
    @extend .btn, .black-btn_hover;
    bordered-btn(2px, $black);
    background: transparent;
    line-height: 30px;
    padding: 0 20px;
  }

  .selected-item {
    padding-right: 25px;
    position: relative;

    + .selected-item {
      margin-top: 5px;
    }
  }

  .selected-item--remove {
    display: block;
    width: 20px; height: 20px;
    position: absolute;
    right: 0; top: 0;
    cursor: pointer;

    &:before, &:after {
      content: '';
      display: block;
      width: 2px; height: 12px;
      background: $black;
      position: absolute;
      top: 50%; left: 50%;
      margin-top: -6px;
      margin-left: -1px;
    }

    &:before {
      transform: rotate(-45deg);
    }

    &:after {
      transform: rotate(45deg);
    }
  }
}

.filter--reset-button {
  display: inline-block;
  text-decoration: none;
  border-bottom: 1px dashed $black;
  margin-top: 20px;
  font-weight: bold;
  text-transform: lowercase;
  font-variant: small-caps;
}

.filter--scroll {
  box-sizing: border-box;
  padding: 64px 43px;
  margin-top: 15px;
  max-height: calc(100% - 30px);
  position: relative;
  overflow: hidden;

  @media $small-handheld {
    padding: 30px 25px;
    margin-top: 0;
    max-height: 100%;
    height: 100% !important;

    .ps-scrollbar-y-rail {
      display: none !important;
    }
  }
}

.filter--group + .filter--group {
  margin-top: 22px;
  padding-top: 16px;
  border-top: 2px solid $dark-peach;
}

.filter--group_price + .filter--group {
  border-top: none;
  margin-top: 20px;
}

.filter--group-header {
  position: relative;
}

.js-collapsible-filter-group-trigger {
  cursor: pointer;
}

.filter--group-arrow {
  display: block;
  width: 12px; height: 8px;
  position: absolute;
  right: 0; top: .7em;
  transition: all .25s ease;

  @media $not-large-desktop {
    top: .4rem;
  }

  .is-collapsed & {
    transform: rotate(180deg);
  }

  &:before, &:after {
    content: '';
    display: block;
    width: 7px; height: 2px;
    background: $black;
    position: absolute;
    top: 0;
  }

  &:before {
    right: 50%;
    transform: rotate(-45deg);
    transform-origin: right top;
  }

  &:after {
    left: 50%;
    transform: rotate(45deg);
    transform-origin: left top;
  }
}

.filter--group-name {
  font-size: 2rem;
  font-weight: bold;
  font-variant: small-caps;
  text-transform: lowercase;
  letter-spacing: 1px;
  line-height: 1;
  padding-right: 15px;

  @media $not-large-desktop {
    font-size: 1.6rem;
  }
}

.filter--group-body {
  overflow: hidden;
  transition: all .25s ease;

  .filter--group:not(.is-collapsed) & {
    padding-top: 20px;
    padding-bottom: 10px;
    max-height: 5000px;
  }

  .filter--group.is-collapsed & {
    max-height: 0;
  }
}

.filter--checkbox + .filter--checkbox {
  margin-top: 2px;
}

.filter--checkbox,
.filter--link {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 20px;
  position: relative;

  label {
    cursor: pointer;
  }

  input {
    display: none;
  }

  label:after {
    content: '';
    display: block;
    width: width('i/check.png'); height: height('i/check.png');
    background: url(/i/check.svg) no-repeat 100% 50%;
    background-size: contain;
    position: absolute;
    right: 0; top: .55em;
    opacity: 0;
    transition: opacity .25s ease;
  }

  .checked {
    font-weight: bold;

    &:after {
      opacity: 1;
    }
  }
}

.filter--link a {
  display: inline-block;
  text-decoration: none;
  border-bottom: 1px solid rgba($black, .5);
}

.filter--group-body_colors {
  display: flex;
  flex-wrap: wrap;
  margin-top: -10px;
}

.filter--colorbox label {
  width: 19px; height: 19px;
  border-radius: 50%;
  border: 4px solid $peach;
  transition: border-color .25s ease;
  margin-right: 10px;
  margin-top: 12px;
  margin-bottom: 12px;
  cursor: pointer;

  &.checked {
    border-color: $black;
  }

  input {
    display: none;
  }
}

.filter--toggler-button {
  @extend .btn;
  background: $dark-peach;
  line-height: 40px;
  width: 152px;
  font-size: 1.4rem;
  border-radius: 20px;
  margin-top: 20px;
}

.js-short-filter-body:not(.is-expanded) {
  .filter--checkbox:nth-child(7) ~ .filter--checkbox {
    display: none;
  }

  .filter--toggler-button {
    &:before {
      content: 'Показать еще';
      display: block;
    }
  }
}

.js-short-filter-body.is-expanded {
  .filter--toggler-button {
    &:before {
      content: 'Свернуть';
      display: block;
    }
  }
}

.filter--price-labels {
  @media $large-desktop {
    display: flex;
    justify-content: space-between;
  }
}

.filter--group-part {
  display: flex;
  align-items: center;

  .inp {
    background: $white;
    border: none;
    line-height: 40px;
    padding: 0;
    text-align: center;
    font-size: 1.4rem;
    font-weight: bold;
    border-radius: 20px;
    width: calc(100% - 30px);

    /* @media $large-desktop {
      width: 78px;
    } */
  }

  + .filter--group-part {
    @media $large-desktop {
      margin-left: 15px;
    }

    @media $not-large-desktop {
      margin-top: 10px;
    }
  }
}

.filter--price-label-item {
  margin-right: 10px;
}

.filter--price-slider {
  position: relative;
  background: $white;
  height: 2px;
  margin-top: 33px;
  margin-left: 5px;
  margin-right: 5px;

  &:before {
    content: '';
    display: block;
    width: 100%; height: 30px;
    position: absolute;
    left: 0; top: 50%;
    transform: translateY(-50%);
  }

  .ui-slider-range {
    height: 2px;
    position: absolute;
    top: 0;
    background: $black;
  }

  .ui-slider-handle {
    box-sizing: border-box;
    display: block;
    width: 10px; height: 10px;
    background: $white;
    border: 2px solid $black;
    border-radius: 50%;
    margin-left: -5px;
    margin-top: -4px;
    position: absolute;
    outline: none;

    &:before {
      content: '';
      display: block;
      width: 30px; height: 30px;
      position: absolute;
      left: 50%; top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.slider--tooltip {
  padding-top: 10px;
}

.mobile-filters-close {
  @extend .btn;
  width: 40px; height: 40px;
  background: transparent;
  position: absolute;
  right: 0; top: 0;
  z-index: 10;

  @media $not-small-handheld {
    display: none;
  }

  &:before, &:after {
    content: '';
    display: block;
    width: 22px; height: 3px;
    position: absolute;
    top: 50%;
    background: $black;
  }

  &:before {
    left: 50%;
    transform: rotate(-45deg) translate(-50%, -50%);
    transform-origin: left top;
  }

  &:after {
    right: 50%;
    transform: rotate(45deg) translate(50%, -50%);
    transform-origin: right top;
  }
}
