
/*   -=========== Catalogue ===========-   */

@media $portrait-tablet {
  .catalogue-page {
    padding-top: 20px;
  }
}

.catalogue-page--group {
  display: flex;
  position: relative;
  z-index: 10;
}

.catalogue-page--pre {
  align-self: flex-start;
  width: 100%;

  .container {
    box-sizing: border-box;
    margin-bottom: 30px;

    @media $large-desktop {
      width: (100% / 5 * 4);
    }

    @media $not-large-desktop {
      padding-right: 20px;
    }

    @media $not-mobile {
      padding-left: 64px;

      @media $handheld {
        padding-left: 34px;
      }
    }

    @media $mobile {
      padding-left: 20px;
    }
  }
}

.catalogue-page .text-container {
  line-height: 1.75;
}

.catalogue-page--teaser {
  display: block;
  line-height: 0;
}

.catalogue-page--caption-line {
  @media $not-mobile {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
}

.catalogue-page--caption {
  @extend .h1;
  /* text-transform: lowercase; */
  margin-bottom: 30px;
}

.catalogue--sorting {
  @media $not-mobile {
    display: flex;
    align-items: center;
  }

  .item {
    display: flex;
    align-items: center;

    @media $not-mobile {
      margin-left: 75px;

      @media $handheld {
        margin-left: 30px;
      }
    }
  }

  @media $mobile {
    margin-bottom: 20px;

    .item + .item {
      margin-top: 1em;
    }
  }

  .item--label {
    font-size: 1.2rem;
    margin-right: 12px;
  }
}

.catalogue-page--categories-list {
  @media $not-small-handheld {
    margin-bottom: 40px;
    border-bottom: 1px solid $light-gray;
  }

  @media $small-handheld {
    .container {
      margin-bottom: 10px;
    }
  }

  @media $mobile {
    margin-bottom: 0;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  li {
    display: block;
    list-style: none;
    flex-basis: 22%;
    margin-right: 4%;
    margin-bottom: 20px;

    @media $notebook {
      flex-basis: 28%;
      margin-right: 8%;
    }

    @media $tablet {
      flex-basis: 46%;

      li:nth-child(odd) {
        margin-right: 8%;
      }
    }

    @media $mobile {
      flex-basis: 100%;
    }
  }

  a {
    display: inline-block;
    vertical-align: top;
    font-size: 1.8rem;
    letter-spacing: 1px;
    text-decoration: none;

    @media $handheld {
      font-size: 1.6rem;
    }
  }

  .is-active a {
    font-weight: bold;
    padding-right: 35px;
    background: url(/i/check.svg) no-repeat 100% .5em;
  }

  @media (min-width: $notebook-breakpoint + 1px) {
    li:nth-child(4n + 4) {
      margin-right: 0;
    }
  }

  @media $notebook {
    li:nth-child(3n + 3) {
      margin-right: 0;
    }
  }
}

.catalogue-page .vitrine .product {
  @media $desktop {
    @media (min-width: $notebook-breakpoint + 1px) {
      &:nth-child(1),
      &:nth-child(6),
      &:nth-child(11) {
        margin-left: calc(100% / 6);
      }
    }
  }

  @media $notebook {
    &:nth-child(1),
    &:nth-child(5),
    &:nth-child(9) {
      margin-left: calc(100% / 5);
    }
  }

  @media $landscape-tablet {
    &:nth-child(1),
    &:nth-child(4),
    &:nth-child(7) {
      margin-left: calc(100% / 4);
    }
  }
}

.page-bottom-text {
  margin-top: 50px;
  padding-bottom: 60px;
}

.catalogue-page--bottom {
  @extend .page-bottom-text;

  @media $not-mobile {
    padding-left: 64px;

    @media $handheld {
      padding-left: 34px;
    }
  }
}

.pager {
  margin-top: 40px;
  margin-bottom: 50px;
  text-align: center;

  ul {
    @extend .menu, .inline-menu;
  }

  li {
    vertical-align: top;
  }

  a {
    box-sizing: border-box;
    display: block;
    width: 43px; height: @width;
    line-height: @width - 4px;
    border: 2px solid transparent;
    border-radius: 50%;
    font-size: 1.4rem;
    text-align: center;
    color: $gray;

    @media $mobile {
      width: 33px; height: @width;
      line-height: @width - 4px;
    }
  }

  .active a {
    border-color: $black;
    color: $black;
  }

  .previous a,
  .next a {
    position: relative;

    &:before, &:after {
      content: '';
      display: block;
      width: 2px; height: 8px;
      position: absolute;
      background: $black;
    }

    &:before {
      bottom: 50%;
      margin-bottom: -2px;
    }

    &:after {
      top: 50%;
    }
  }

  .previous a {
    &:before, &:after {
      right: 50%;
      margin-right: 8px;
    }

    &:before {
      transform: rotate(45deg);
      transform-origin: right bottom;
    }

    &:after {
      transform: rotate(-45deg);
      transform-origin: right top;
    }
  }

  .next a {
    &:before, &:after {
      left: 50%;
      margin-left: 8px;
    }

    &:before {
      transform: rotate(-45deg);
      transform-origin: left bottom;
    }

    &:after {
      transform: rotate(45deg);
      transform-origin: left top;
    }
  }
}


// Sections

.goods-sections--caption {
  box-sizing: border-box;
  border-right: 1px solid $almost-white;

  @media (min-width: $notebook-breakpoint + 1px) {
    flex-basis: (100% / 6);
    margin-left: (100% / 6);
  }

  @media $notebook {
    flex-basis: (100% / 5);
    padding-left: 3%;
  }

  @media $landscape-tablet {
    flex-basis: (100% / 4);
    padding-left: 3%;
  }

  @media $small-handheld {
    flex-basis: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }

  .subcaption {
    position: relative;
    top: 13%;
    text-transform: lowercase;

    @media $mobile {
      padding-top: 20px;
    }
  }
}

.goods-sections {
  align-items: stretch;

  @media $small-handheld {
    flex-wrap: wrap;
  }

  .item {
    box-sizing: border-box;
    border-right: 1px solid $almost-white;
    border-top: 1px solid $almost-white;
    position: relative;
    background-position: 50%;
    background-size: cover;
    text-decoration: none;

    @media (min-width: $notebook-breakpoint + 1px) {
      flex-basis: (100% / 6);
    }

    @media $notebook {
      flex-basis: (100% / 5);
    }

    @media $landscape-tablet {
      flex-basis: (100% / 4);
    }

    @media $portrait-tablet {
      flex-basis: (100% / 3);
    }

    @media $mobile {
      flex-basis: 100%;
      background: $white !important;
      margin-left: 20px;
      margin-right: 20px;
      border-right: none;
      order: 2;
    }

    &:before {
      content: '';
      display: block;
      padding-bottom: 148%;
      background: rgba($peach, .49);
      opacity: 0;
      transition: opacity .25s ease;

      @media $mobile {
        display: none;
      }
    }

    &:hover:before {
      opacity: 1;
    }

    &:hover .item--caption .arrow-icon {
      opacity: 1;
    }
  }

  @media $mobile {
    .item:first-child {
    /* .additional-vitrine--header + .item { */
      border-top: none;
    }

    .vitrine--header {
      order: 1;
    }

    .additional-vitrine--header {
      display: block;
      width: 100%;
      order: 3;
    }
  }

  .item--caption {
    font-size: 1.8rem;
    font-weight: bold;
    line-height: 1.2;
    text-transform: lowercase;
    position: absolute;
    left: 17%;
    max-width: 70%;
    z-index: 5;
    /* white-space: pre; */

    @media $tablet {
      font-size: 1.4rem;
    }

    @media $mobile {
      position: static;
      max-width: 100%;
      font-size: 1.4rem;
      font-weight: normal;
      padding: 15px 0;
      /* white-space: normal; */
    }

    .arrow-icon {
      display: block;
      width: 15px; height: 26px;
      position: absolute;
      left: 0;
      opacity: 0;
      transition: opacity .25s ease;

      @media $mobile {
        display: none;
      }

      &:before {
        content: '';
        display: block;
        width: 16px; height: @width;
        border-top: 2px solid $black;
        border-right: 2px solid $black;
        transform: rotate(45deg);
        margin-left: -7px;
        margin-top: 4px;
      }
    }
  }

  .item_top-caption .item--caption {
    top: 14%;

    .arrow-icon {
      top: 100%;
      margin-top: 45px;
    }
  }

  .item_bottom-caption .item--caption {
    bottom: 14%;

    .arrow-icon {
      bottom: 100%;
      margin-bottom: 45px;
    }
  }
}

.goods-sections_one-row {
  .goods-sections--caption {
    margin-left: 0;
  }

  @media $notebook {
    .item + .item + .item + .item ~ .item {
      display: none;
    }
  }

  @media $landscape-tablet {
    .item + .item + .item ~ .item {
      display: none;
    }
  }

  @media $portrait-tablet {
    .item + .item ~ .item {
      display: none;
    }

    .goods-sections--caption {
      flex-basis: (100% / 3);
    }
  }
}

@media $mobile {
  .goods-sections--caption {
    border-bottom: none;

    &:after {
      display: none;
    }

    .vitrine--header-content {
      background: $white;
      padding: 0;
    }

    .vitrine--header-caption {
      font-size: 1.8rem;
      margin-bottom: 0;
      padding-top: 30px;
      padding-bottom: 10px;

      &:after {
        display: none;
      }
    }

    .vitrine--header-description {
      display: none;
    }
  }

  a.goods-sections--link {
    background: $white;

    &:before {
      display: none;
    }

    .additional-vitrine--header-content {
      padding: 0;
      position: static;
    }

    .additional-vitrine--caption {
      margin-left: 20px;
      margin-right: 20px;
      margin-bottom: 0;
      font-size: 1.4rem;
      border-top: 1px solid $almost-white;
      padding: 15px 0;

      br {
        display: none;
      }

      .arrow-icon {
        left: auto; right: 15px; top: 50%;
        transform: rotate(90deg) translateY(-50%);
        margin-top: -12px;

        &:before {
          width: 8px; height: @width;
          margin-left: 0;
          margin-top: 8px;
        }
      }
    }
  }
}

.brands-section {
  background: $peach;
  padding-top: 65px;
  padding-bottom: 65px;

  @media $not-mobile {
    .wrapper {
      display: flex;
      align-items: center;
    }
  }

  .brands-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex: 1;

    @media $not-mobile {
      justify-content: flex-start;
      margin-left: 5%;
    }

    @media $mobile {
      justify-content: center;
    }

    .item {
      width: 20%;
      text-align: center;
      margin-top: 10px;
      margin-bottom: 10px;
      text-decoration: none;

      @media $mobile {
        width: auto;
        margin-left: 5%;
        margin-right: 5%;
      }
    }
  }
}

.brands-section--caption {
  @extend .subcaption;
  padding-bottom: 20px;
  text-transform: lowercase;

  @media $mobile {
    margin-bottom: 30px;
    font-size: 1.8rem;
  }
}

.catalogue--tags {
  .item {
    font-size: 1.2rem;
    line-height: 1.75;
    color: $gray;
    margin-right: 15px;
    text-decoration: none;

    &:hover {
      color: $black;
    }
  }

  @media $mobile {
    display: none;
  }
}

.mobile-filters-link {
  @extend .btn, .black-btn, .rounded-btn, .h40btn;
  width: 160px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 30px;

  /* @extend .btn;
  background: transparent;
  width: 21px; height: 42px;
  position: fixed;
  left: 0; top: 50%;
  z-index: 100;
  transform: translateY(-50%);

  &:before {
    content: '';
    display: block;
    width: 42px; height: @width;
    background: #f3d2ac;
    border: 7px solid #f9bf8f;
    border-radius: 50%;
    box-sizing: border-box;
    position: absolute;
    right: 0; top: 0;
  }

  &:after {
    content: '';
    display: block;
    width: 5px; height: @width;
    border-top: 2px solid $black;
    border-right: 2px solid $black;
    transform: rotate(45deg) translateY(-50%);
    position: absolute;
    left: 100%; top: 50%;
    margin-left: 5px;
  }

  .filter & {
    position: absolute;
    left: 100%;
    margin-top: - ($mobile-header-height / 2);
    z-index: 0;
    overflow: hidden;
  }

  @media $not-small-handheld {
    display: none;
  } */
}
