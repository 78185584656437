
/*   -=========== Menus ===========-   */

// Base

.menu {
  ul, ol, li {
    margin: 0;
    list-style: none;
    user-select: none;
  }

  a {
    text-decoration: none;
  }

  .active > a {
    cursor: default;
  }

  li, a {
    user-select: none;
  }

  a:not([href]) {
    cursor: default;
  }
}

.inline-menu {
  li {
    display: inline-block;
  }
}

.horizontal-menu {
  ul {
    clearfix();
  }

  li {
    float: left;
  }
}

.vertical-menu {
  li {
    display: block;
  }
}

.justify-menu {
  > ul, > ol {
    line-height: 0;
    text-align: justify;

    &:after {
      width: 100%;
      height: 0;
      content: "";
      font-size: 0;
      line-height: 0;
      display: inline-block;
      visibility: hidden;
      overflow: hidden;
    }

    > li {
      display: inline-block;
    }
  }
}

.table-menu {
  ul {
    display: table;
    table-layout: fixed;
    width: 100%;
  }

  li {
    display: table-cell;
  }
}


// Header menu

.header--menu {
  @extend .menu;
  width: 100%;

  > ul {
    display: flex;
    justify-content: space-between;
  }

  ul:not(.dropdown) > li > a {
    display: block;
    line-height: 57px;
    font-size: 1.6rem;
    font-weight: bold;
    font-variant: small-caps;
    text-transform: lowercase;
    position: relative;

    @media $landscape-mobile {
      font-size: 1.3rem;
    }

    &:after {
      content: '';
      display: block;
      width: 100%; height: 3px;
      background: $black;
      position: absolute;
      left: 50%; bottom: -1px;
      transform: translateX(-50%);
      opacity: 0;
      max-width: 0;
      transition: all .25s ease;
    }
  }

  ul:not(.dropdown) > li.is-active > a,
  ul:not(.dropdown) > li.is-hovered > a,
  ul:not(.dropdown) > li > a:hover {
    &:after {
      opacity: 1;
      max-width: 140px;
    }
  }

  .has-dropdown {
    position: relative;
  }

  .has-dropdown .has-dropdown {
    position: static;

    > a {
      position: relative;

      &:after {
        content: '';
        display: block;
        width: 5px; height: @width;
        border-top: 2px solid $black;
        border-right: 2px solid $black;
        position: absolute;
        right: 25px; top: 50%;
        transform: rotate(45deg) translateY(-50%);
        opacity: 0;
      }
    }

    &:hover > a:after {
      opacity: 1;
    }
  }

  .dropdown {
    box-sizing: border-box;
    display: none;
    position: absolute;
    z-index: 100;

    > li > a {
      display: block;
      padding-top: 5px;
      padding-bottom: 5px;
      white-space: nowrap;
    }

    a:hover {
      color: $gray;
    }
  }

  .has-dropdown.is-hovered > .dropdown {
    display: block;
  }

  .dropdown_first-level {
    left: -35px; top: 100%;
    margin-top: 1px;
    background: $white;
    padding-top: 25px;
    padding-bottom: 35px;
    box-shadow: 0 5px 20px rgba($black, .15);

    > li > a {
      padding-left: 35px;
      padding-right: 65px;
    }
  }

  .dropdown_second-level {
    left: 100%; top: 0;
    min-height: 100%;
    background: $almost-white;
    padding-top: 25px;
    padding-bottom: 35px;
    overflow: hidden;

    > li > a {
      padding-left: 35px;
      padding-right: 35px;
    }
  }
}


// Footer menu

.footer--menu {
  @extend .menu;
  letter-spacing: 1px;

  li + li {
    margin-top: 1.5em;
  }
}


// Breadcrumbs

.breadcrumbs {
  padding-top: 30px;
  padding-bottom: 30px;
  font-size: 1.2rem;

  @media $small-handheld {
    display: none;
  }

  li {
    display: inline-block;
    vertical-align: middle;
    position: relative;
  }

  li + li {
    margin-left: 22px;

    &:before {
      content: '';
      display: block;
      width: 6px; height: @width;
      border-top: 1px solid $gray;
      border-right: 1px solid $gray;
      position: absolute;
      top: 50%; left: -19px;
      transform: rotate(45deg) translateY(-60%);
    }
  }

  li:last-child:before {
    border-top-color: $black;
    border-right-color: $black;
  }

  a, span {
    color: $gray;
  }

  a {
    text-decoration: none;
    transition: color .25s ease;

    &:hover {
      color: $black;
    }
  }
}


// Mobile menu

.mobile-menu {
  display: none;
  position: fixed;
  left: 0; top: $mobile-header-height + 1px;
  z-index: 100;
  width: 100%; height: 'calc(100% - %s)' % ($mobile-header-height + 1px);
  background: $white;
  overflow-y: auto;

  .mobile-menu-is-opened & {
    display: block;
  }

  .menu {
    margin-bottom: 5px;

    a {
      display: block;
    }

    > li {
      border-bottom: 1px solid $light-gray;

      > a {
        padding: 15px 45px 15px 22px;
        font-size: 1.6rem;
        font-weight: bold;
        font-variant: small-caps;
        text-transform: lowercase;
      }
    }

    .dropdown {
      overflow: hidden;
      max-height: 0;
      opacity: 0;
      transition: all .25s ease;
    }

    .is-hovered > .dropdown {
      max-height: 2000px;
      opacity: 1;
    }

    > li > .dropdown {
      > li > a {
        padding: 7px 45px 7px 40px;
        font-size: 1.4rem;
      }
    }

    > li > .dropdown > li > .dropdown {
      background: $almost-white;

      > li > a {
        padding: 7px 45px 7px 40px;
        font-size: 1.4rem;
      }
    }

    > li.is-hovered > .dropdown {
      border-top: 1px solid $light-gray;
      padding-top: 10px;
      padding-bottom: 15px;
    }

    > li > .dropdown > li.is-hovered > .dropdown {
      padding-top: 10px;
      padding-bottom: 15px;
    }
  }

  .info-menu {
    @extend .menu;
    margin-bottom: 20px;

    a {
      display: block;
      padding: 7px 40px 7px 22px;
      font-size: 1.2rem;
    }

    .dropdown {
      padding-left: 30px;
    }
  }
}

.mobile-menu--city {
  background: url(/i/geolocation-icon.png) no-repeat 0 50%;
  padding-left: 30px;
  color: $gray;
  font-size: 1.2rem;
}

.mobile-menu--city-container {
  padding: 17px 40px 17px 22px;
}

.mobile-menu--footer {
  padding: 10px 40px 35px 22px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 1.2rem;
  font-weight: bold;

  &, a {
    color: $gray;
  }
}


// Sitemap

.text-container.sitemap {
  ul {
    margin-top: 0;
  }

  ul li + li {
    margin-top: .5em;
  }

  a {
    text-decoration: none;
  }
}
