
/*   -=========== Actions ===========-   */

.actions-rotator {
  white-space: nowrap;

  &:first-child {
    margin-top: -1px;
  }

  .item {
    white-space: normal;
    width: 100%; height: 476px;
    display: inline-flex !important;
    flex-direction: column;
    justify-content: center;
    background: url(/i/action-bg.jpg) no-repeat 50% / cover;

    @media $large-desktop {
      .row & {
        padding-left: 8%;
      }
    }

    @media $desktop {
      @media $not-large-desktop {
        height: 525px;
      }
    }

    @media $mobile {
      height: 320px;
    }
  }

  .item--caption {
    font-size: 6rem;
    font-weight: bold;
    text-transform: lowercase;
    letter-spacing: 2px;
    line-height: 1.2;
    position: relative;
    padding-bottom: 40px;
    margin-bottom: 30px;

    @media $tablet {
      font-size: 4.5rem;
    }

    @media $mobile {
      font-size: 2.4rem;
      letter-spacing: 0;
      padding-bottom: 20px;
      margin-bottom: 20px;
    }

    &:after {
      content: '';
      display: block;
      width: 31px; height: 2px;
      background: $black;
      position: absolute;
      left: 0; bottom: 0;
    }
  }

  .item--text {
    margin-bottom: 40px;

    @media $mobile {
      padding-right: 20px;
      margin-bottom: 25px;

      br {
        display: none;
      }
    }
  }

  .item--link {
    @extend .btn;
    bordered-btn(3px, $black);
    line-height: 54px;
    padding: 0 50px;
    border-radius: 27px;
    font-size: 1.4rem;

    &:hover {
      background: $black;
      color: $white;
    }

    @media $mobile {
      bordered-btn(2px, $black);
      line-height: 39px;
      padding: 0 15px;
    }
  }

  .slick-dots {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    @media $large-desktop {
      right: (5 * 100% / 6);
      margin-right: 5%;
    }

    @media $not-large-desktop {
      right: 5%;
    }

    li {
      list-style: none;
    }

    li + li {
      margin-top: 13px;
    }

    button {
      display: block;
      width: 12px; height: 12px
      background: $white;
      border-radius: 50%;
      font-size: 0;
    }

    .slick-active button {
      background: $black;
    }
  }
}
