
/*   -=========== Order ===========-   */

// Empty basket
// ------------

.empty-basket-page {
  padding-top: 30px;
  padding-bottom: 50px;

  .h1 {
    margin-bottom: 20px;
  }
}

.empty-basket-page--button {
  @extend .btn, .black-bordered-btn, .black-btn_hover, .h40btn;
  padding: 0 25px;
  font-size: 12px;
}


// Common
// ------

.order-page {
  padding-top: 25px;
  padding-bottom: 100px;

  @media $mobile {
    padding-bottom: 55px;
  }
}

.order-page--caption {
  @extend .h1;
  /* text-transform: lowercase; */
  margin-bottom: 20px;

  @media $mobile {
    margin-bottom: 5px;
  }
}

.order-page--container {
  @media $not-small-handheld {
    display: flex;
    align-items: flex-start;
  }
}

.order-page--content {
  flex: 1;
}

.order-page--aside {
  flex-shrink: 0;
  box-sizing: border-box;
  width: 259px; height: 368px;
  padding: 30px 40px 80px 45px;
  margin-left: 45px;
  margin-top: 58px;
  background: url(/i/basket-aside-bg.png) no-repeat 50%;
  position: relative;

  @media $handheld {
    width: 230px;
    padding: 20px 20px 60px 20px;
    margin-left: 15px;
  }

  @media $small-handheld {
    width: auto; height: auto;
    padding: 0;
    margin: 0;
    background: transparent;

    .order-form & {
      margin-top: 30px;
    }
  }
}

.order-page--aside-caption {
  @extend .h1;
  text-transform: lowercase;
  margin-bottom: 20px;

  @media $small-handheld {
    display: none;
  }
}

.order-page--aside-button {
  @extend .btn, .turq-btn, .black-btn_hover, .r20btn, .h40btn;
  width: 172px;
  font-size: 1.4rem;

  @media $not-small-handheld {
    position: absolute;
    left: 45px; bottom: 40px;

    @media $handheld {
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

@media $small-handheld {
  .order-page--aside-button {
    background: $peach;
    margin-top: 25px;
  }
}

@media $small-handheld {
  .order-page--aside-price:not(.order-page--aside-price_total) {
    display: flex;

    .order-page--aside-price-value {
      margin-left: 5px;
    }
  }
}

.order-page--aside-price_total {
  font-weight: bold;
}

@media $not-mobile {
  .order-page--aside-caption + .order-page--aside-price_total {
    margin-top: 50px;
  }
}

.order-page--aside-price-label {
  font-size: 1.4rem;
  margin-bottom: 3px;
}

.order-page--aside-price_total .order-page--aside-price-value {
  font-size: 2.4rem;

  .currency {
    font-size: 1.8rem;
  }
}

.order-page--aside-price + .order-page--aside-price {
  margin-top: 20px;

  @media $small-handheld {
    margin-top: 15px;
  }
}

.order-steps {
  @extend .menu;
  border-bottom: 1px solid $gray;
  display: flex;
  margin-bottom: 30px;

  li {
    margin-bottom: -1px;
  }

  li + li {
    margin-left: 3vw;
  }

  li span, li a {
    display: block;
    line-height: 56px;
    border-bottom: 3px solid transparent;
    font-size: 1.9rem;
    font-weight: bold;
    font-variant: small-caps;
    text-transform: lowercase;
    letter-spacing: 1px;
    color: $gray;
  }

  li.is-active {
    span, a {
      border-bottom-color: $black;
      color: $black;
    }
  }

  @media $mobile {
    display: none;
  }
}

.order-page--footer {
  text-align: right;

  @media $small-handheld {
    display: none;
  }
}

.order-page--back-button {
  @extend .btn, .turq-btn, .peach-btn_hover, .h40btn, .r20btn;
  font-size: 1.4rem;
  padding: 0 40px;
}


// Order table
// -----------

.order-table {
  margin-bottom: 50px;

  @media $mobile {
    margin-bottom: 30px;
  }

  th {
    font-size: 1.2rem;
    font-weight: normal;
    color: $gray;
    letter-spacing: 1px;
    white-space: nowrap;
  }

  th {
    padding: 5px 15px 45px;

    @media $handheld {
      padding-left: 5px;
      padding-right: 5px;
    }

    @media $mobile {
      display: none;
    }
  }

  .product {
    border-bottom: 1px solid $gray;
    position: relative;

    @media $mobile {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      padding-top: 40px;
      padding-bottom: 15px;
    }
  }

  .product > td {
    padding: 15px;

    @media $handheld {
      padding-left: 5px;
      padding-right: 5px;
    }
  }

  @media $not-mobile {
    .product > td {
      height: 105px;
    }
  }

  td.product--image {
    text-align: center;
    padding-left: 0;

    img {
      max-width: 120px;
      max-height: 180px;
    }

    @media $mobile {
      width: 90px;
      padding-left: 0;
      padding-right: 0;
      margin-right: 10px;

      img {
        max-width: 100%;
      }
    }
  }

  td.product--description {
    box-sizing: border-box;
    /* vertical-align: top; */

    @media $mobile {
      width: calc(100% - 100px);
      padding: 10px 0;
    }
  }

  .product--name {
    font-size: 1.4rem;
    font-weight: bold;
    margin-bottom: 1em;

    a {
      text-decoration: none;
    }
  }

  td.product--price {
    font-size: 1.4rem;
    font-weight: bold;
    text-align: center;

    @media $mobile {
      padding: 0;
    }
  }

  .product--amount {
    text-align: center;
  }

  td.product--controls {
    text-align: right;

    @media $mobile {
      position: absolute;
      top: 10px; right: 0;
      padding: 0;
    }
  }

  .product--remove-button {
    display: inline-block;
    vertical-align: middle;
    width: 30px; height: 30px;
    position: relative;
    font-size: 0;

    &:before, &:after {
      content: '';
      display: block;
      width: 3px; height: 25px;
      background: $gray;
      position: absolute;
      left: 50%; top: 50%;
      margin-left: -2px;
      margin-top: -12px;
      transition: background-color .25s ease;
    }

    &:before {
      transform: rotate(-45deg);
    }

    &:after {
      transform: rotate(45deg);
    }

    &:hover {
      &:before, &:after {
        background: $black;
      }
    }
  }
}

.product--params {
  font-size: 1.4rem;
  color: $gray;

  .item + .item {
    margin-top: 1em;
  }

  .item {
    display: flex;
    align-items: flex-start;
  }

  .item_color {
    align-items: center;
  }

  .item--value {
    margin-left: 5px;
  }

  .item--color {
    width: 25px; height: 25px;
    border-radius: 50%;
    margin-left: 10px;
  }
}

.order-success-page {
  box-sizing: border-box;
  position: relative;

  @media $not-mobile {
    padding: 20px 0;
    min-height: 716px;
    background: url(/i/order-success-bg.png) no-repeat 50% / auto 100%;
  }
}

.order-success-page--content {
  box-sizing: border-box;
  max-width: 855px;
  padding: 60px 85px 75px;
  background: $white url(/i/order-success-pic.jpg) no-repeat 100% 50%;

  @media $not-mobile {
    width: calc(100% - 40px);
    box-shadow: 0 0 15px rgba($black, .22);
    position: absolute;
    left: 50%; top: 50%;
    transform: translate(-50%, -55%);
  }

  @media $mobile {
    padding: 15px 20px 55px;
    background-position: 100% -15px;
    background-size: 120px auto;
  }
}

.order-success-page--caption {
  @extend .h1;
  text-transform: lowercase;
  margin-bottom: 35px;
}

.order-success-page--number-caption {
  color: $gray;
  letter-spacing: 1px;
  font-size: 1.4rem;
  margin-bottom: 10px;
}

.order-success-page--number {
  @extend .h1;
  margin-bottom: 40px;
}

.order-success-page--text {
  line-height: 1.75;
  margin-bottom: 55px;
}

.order-success-page--button {
  @extend .btn, .turq-btn, .peach-btn_hover;
  line-height: 54px;
  border-radius: 26px;
  padding: 0 35px;
  font-size: 1.4rem;
}

.order-page--delivery-info,
.pick-point-info {
  margin-top: 20px;

  @media $not-mobile {
    padding-left: 40px;
  }
}

.order-page--delivery-info {
  p {
    margin-top: .5em;
  }
}

.pick-point-info {
  font-size: 1.2rem;
}
