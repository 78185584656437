
/*   -=========== Common components ===========-   */

.product-icons-prototype {
  .icon {
    display: block;
    width: 30px; height: @width;
    border-radius: 50%;
    box-sizing: border-box;
  }

  .icon + .icon {
    margin-top: 18px;
  }

  .icon_discount {
    background: #badfe8 url(/i/percent-icon.svg) no-repeat 50%;
    background-size: size('i/percent-icon.png');
  }

  .icon_leader {
    background: url(/i/star-icon.svg) no-repeat 50%;
    background-size: size('i/star-icon.png');
    border: 1px solid $gray;
  }

  .icon_new {
    background: url(/i/new-icon.svg) no-repeat 50%;
    background-size: size('i/new-icon.png');
    border: 1px solid $gray;
  }

  .icon_free-delivery {
    background: url(/i/free-delivery-icon.svg) no-repeat 50%;
    background-size: size('i/free-delivery-icon.png');
    border: 1px solid $gray;
  }

  .icon_fast-delivery {
    background: url(/i/fast-delivery-icon.svg) no-repeat 50%;
    background-size: size('i/fast-delivery-icon.png');
    border: 1px solid $gray;
  }

  .icon_best-price {
    background: url(/i/best-price-icon.svg) no-repeat 50%;
    background-size: size('i/best-price-icon.png');
    border: 1px solid $gray;
  }
}

.product-params-table-prototype {
  letter-spacing: 1px;

  th {
    text-align: left;
  }

  th, td {
    @media $not-mobile {
      padding-bottom: 15px;
    }

    @media $mobile {
      display: block;
    }
  }

  @media $mobile {
    tr {
      display: block;
    }

    tr + tr {
      margin-top: 15px;
    }
  }
}

.currency {
  span {
    display: none;
  }

  &:before {
    content: 'q';
    font-family: 'rouble';
    font-variant: normal;
  }
}
