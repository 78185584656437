
/*   -=========== Brands ===========-   */

.brands-page {
  padding-bottom: 50px;

  @media $small-handheld {
    padding-top: 30px;
  }
}

.brands-page--caption {
  @extend .h1;
  /* text-transform: lowercase; */
  margin-bottom: 20px;
}

.brands-page--caption_list-caption {
  margin-bottom: 0;
}

.brands-page--popular {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 50px;
  border-bottom: 1px solid $light-gray;
  position: relative;

  @media $mobile {
    flex-direction: column;
    align-items: center;
    padding-bottom: 30px;
  }

  .item {
    list-style: none;
    display: flex;
    align-items: center;
    width: 20%;
    justify-content: center;

    @media $not-mobile {
      margin-bottom: 20px;
    }

    @media $mobile {
      flex-direction: column;
      width: 100%;
    }
  }

  /* @media $not-mobile {
    .item + .item {
      margin-left: 20px;
    }
  } */
}

.brands-page--menu {
  .item--logo {
    box-sizing: border-box;
    display: flex;
    height: 100%;
    padding: 25px 0;
    align-items: center;
    position: relative;
    cursor: pointer;

    &:after {
      content: '';
      display: block;
      width: 100%; height: 3px;
      background: $black;
      position: absolute;
      bottom: 0; left: 0;
      opacity: 0;
    }
  }

  .item--trigger {
    cursor: pointer;
  }

  .item--details {
    display: none;
    position: absolute;
    left: -45px; top: 100%;
    margin-top: 1px;
    z-index: 10;
    width: 100%;
    padding: 45px 45px 50px;
    background: $almost-white;
    box-shadow: 16px 16px 29px rgba($black, .13);

    @media $not-large-desktop {
      left: -20px;
      padding-left: 20px;
      padding-right: 20px;
    }

    @media $mobile {
      position: relative;
      top: auto; left: auto;
      margin-left: -20px;
      margin-right: -20px;
      padding-top: 20px;
      padding-bottom: 30px;
      /* flex-shrink: 0; */
    }

    &:before {
      content: '';
      display: block;
      width: 100%; height: 1px;
      position: absolute;
      left: 0;
      top: -1px;
    }
  }

  .item.is-active .item--logo:after {
    opacity: 1;
  }

  .item.is-active .item--details {
    display: block;
  }

  .item--details-menu {
    margin-bottom: 45px;
    max-width: 900px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    @media $mobile {
      height: auto !important;
      margin-bottom: 20px;
    }

    li {
      list-style: none;
      padding: 5px 0;

      @media $not-mobile {
        width: 30%;
        margin-right: 3%;
      }
    }

    a {
      text-decoration: none;

      &:hover {
        color: $gray;
      }
    }
  }

  .item--details-caption {
    @extend .h1;
    margin-bottom: 10px;
  }

  .item--details-text {
    font-size: 1.4rem;
  }
}

.brands-page--brands-list {
  display: flex;
  flex-wrap: wrap;
  position: relative;

  .item--details {
    top: auto;
    margin-top: 10px;
  }

  .list-group {
    box-sizing: border-box;
    width: 20%;
    padding-bottom: 55px;
    padding-top: 80px;

    @media $portrait-tablet {
      width: 33.33%;
    }

    @media $mobile {
      width: 100%;
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }

  @media $not-small-handheld {
    .list-group:nth-child(5) ~ .list-group {
      border-top: 1px solid $light-gray;
    }
  }

  @media $portrait-tablet {
    .list-group:nth-child(3) ~ .list-group {
      border-top: 1px solid $light-gray;
    }
  }

  @media $mobile {
    .list-group:first-child ~ .list-group {
      border-top: 1px solid $light-gray;
    }
  }

  .list-group--letter {
    @extend .h1;
    margin-bottom: 30px;
  }

  .list-group--items .item {
    list-style: none;
  }

  .list-group--items .item + .item {
    margin-top: 10px;
  }

  .list-group--items .item {
    .item--trigger {
      text-decoration: none;
      font-size: 1.4rem;
      color: $gray;

      &:hover {
        color: $black;
      }
    }

    &.is-active .item--trigger {
      color: $black;
    }
  }
}
