
/*   -=========== Errors ===========-   */

.error-page {
  &.error-404 {

  }

  .error-header {
    line-height: 200px;
    border-bottom: 1px solid $light-gray;
    text-align: center;

    @media $mobile {
      height: 59px;
      line-height: 57px;
    }
  }

  .error-header--group {
    display: inline-block;
    vertical-align: middle;
    line-height: 1.2;
  }

  .error-logo {
    display: inline-block;
    vertical-align: middle;
    width: width('i/prouyut-logo.png'); height: height('i/prouyut-logo.png');
    background: url(/i/prouyut-logo.png) no-repeat 50%;
    font-size: 0;
    text-decoration: none;

    @media $mobile {
      background: url(/i/prouyut-small-logo.png) no-repeat 50%;
      width: width('i/prouyut-small-logo.png'); height: height('i/prouyut-small-logo.png');
    }
  }

  .error-slogan {
    font-size: 1.2rem;
    margin-top: 10px;

    @media $mobile {
      display: none;
    }
  }

  .error-text {
    padding: 30px 20px;
    background: url(/i/404-bg.jpg) no-repeat 50% 0;
  }

  .error-code {
    background: url(/i/404.png) no-repeat 50% / contain;
    font-size: 0;
    max-width: width('i/404.png');
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;

    &:before {
      content: '';
      display: block;
      padding-bottom: 50%;
    }
  }

  .error-note {
    font-size: 1.4rem;
    text-align: center;
  }

  .error-button {
    @extend .btn, .turq-btn, .black-btn_hover;
    line-height: 54px;
    width: 236px;
    border-radius: 25px;
    margin-top: 30px;
  }
}
