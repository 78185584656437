
/*   -=========== Contact ===========-   */

@media $small-handheld {
  .contact-page {
    padding-top: 30px;
  }
}

.contact--caption {
  @extend .h1;
  /* text-transform: lowercase; */
  margin-bottom: 50px;
  padding-bottom: 20px;
  position: relative;

  &:after {
    content: '';
    display: block;
    width: 31px; height: 2px;
    background: $black;
    position: absolute;
    left: 0; top: 100%;
  }
}

.contact--map {
  position: relative;

  > .map {
    width: 100%;
    height: 430px;
  }
}

.contact--info {
  margin-bottom: 100px;

  @media $small-handheld {
    margin-bottom: 30px;

    .row {
      flex-wrap: wrap;
    }

    .column {
      width: calc(50% - 10px);
      margin-bottom: 30px;
    }
  }

  @media $not-small-handheld {
    .column + .column {
      margin-left: 20px;
    }
  }

  @media $mobile {
    .column {
      width: 100%;
    }
  }
}

.contact--group + .contact--group {
  margin-top: 30px;
}

.contact--group-caption {
  font-size: 1.8rem;
  font-weight: bold;
  text-transform: lowercase;
  margin-bottom: 15px;
}

.contact--group-text {
  font-size: 1.4rem;
  line-height: 1.75;
}

.contact--group_emails {
  .item + .item {
    margin-top: 15px;
  }

  .item a {
    text-decoration: none;
    font-weight: bold;
  }

  .item--description {
    display: block;
  }

  .item_callback-link a {
    text-decoration: underline;
  }
}
