
/*   -=========== Homepage ===========-   */

.teaser-tile {
  display: block;
  height: 100%;
  line-height: 0;
  position: relative;

  img {
    width: 100%; height: 100%;
    object-fit: cover;
    object-position: 0 50%;
  }
}

.teaser-tile--text {
  position: absolute;
  left: 10%;
  width: 80%;
  font-size: 3rem;
  font-weight: bold;
  line-height: 1.2;
  color: $black;

  &:not(.teaser-tile--text_bottom) {
    top: 10%;
  }
}

.teaser-tile--text_bottom {
  bottom: 10%;
}

.homepage-text {
  background: $peach;
  padding-top: 60px;
  padding-bottom: 40px;

  @media $mobile {
    padding-top: 40px;
    padding-bottom: 20px;
  }

  + .brands-section {
    padding-top: 20px;
  }
}

.homepage-text--caption {
  font-size: 0;
  height: 34px;
  background: url(/i/prouyut-text-logo.png) no-repeat 0 0;
  padding-bottom: 37px;
  margin-bottom: 30px;
  position: relative;

  &:after {
    content: '';
    display: block;
    width: 31px; height: 2px;
    background: $black;
    position: absolute;
    bottom: 0; left: 0;
  }
}

.homepage-text--subcaption {
  @extend .h1;
  text-transform: lowercase;

  @media $mobile {
    font-size: 1.8rem;
  }
}

.homepage-text--subcaption_separated {
  padding-bottom: 17px;
  margin-bottom: 20px;
  position: relative;

  &:after {
    content: '';
    display: block;
    width: 31px; height: 2px;
    background: $black;
    position: absolute;
    left: 0; bottom: 0;
  }
}

.homepage-features {
  margin-top: 35px;
  display: flex;
  justify-content: space-between;

  .feature {
    flex: 1;
  }

  .feature--pic {
    margin-bottom: 20px;
  }

  .feature--caption {
    font-weight: bold;
    margin-bottom: 15px;
  }

  .feature-separator {
    width: 40px; height: 40px;
    margin-top: -5%;
    position: relative;
    align-self: center;
    margin-left: 2vw;
    margin-right: 2vw;

    &:before {
      content: '';
      display: block;
      width: 17px; height: @width;
      border-top: 2px solid $black;
      border-right: 2px solid $black;
      transform: rotate(45deg);
      position: absolute;
      left: 50%; top: 50%;
      margin-left: -14px;
      margin-top: -9px;
    }
  }
}

.catalogue-menu-section {
  padding-top: 60px;
  padding-bottom: 120px;

  .row + .row {
    margin-top: 60px;
  }

  @media $mobile {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.catalogue-menu-subsection + .catalogue-menu-subsection {
  margin-top: 60px;
}

.catalogue-menu-section--caption {
  display: block;
  text-decoration: none;
  @extend .h1;
  text-transform: lowercase;
  padding-bottom: 23px;
  margin-bottom: 25px;
  position: relative;

  &:after {
    content: '';
    display: block;
    width: 31px; height: 2px;
    background: $black;
    position: absolute;
    bottom: 0; left: 0;
  }

  @media $mobile {
    padding-bottom: 0;
    margin-bottom: 0;
    font-size: 1.8rem;

    &:after {
      display: none;
    }

    &:before {
      content: '';
      display: block;
      width: 16px; height: @width;
      border-top: 2px solid $black;
      border-right: 2px solid $black;
      transform: rotate(45deg);
      position: absolute;
      right: 20px; top: 50%;
      margin-top: -8px;
      margin-right: -15px;
    }
  }
}

.catalogue-menu-section--subcaption {
  display: block;
  text-decoration: none;
  @extend .h1;
  text-transform: lowercase;
  padding-bottom: 30px;
  border-bottom: 1px solid $almost-white;
  margin-bottom: 30px;
}

.catalogue-menu {
  @extend .menu;
  padding-right: 3vw;

  ul {
    margin-top: 25px;
  }

  li + li {
    margin-top: 10px;
  }

  li a {
    color: $gray;
    text-transform: lowercase;

    &:hover {
      color: $black;
    }
  }
}

.catalogue-menu--section {
  display: inline-block;
  @extend .h3;
  text-decoration: none;
  min-height: 2.4em;
}

.catalogue-menu-spoiler {
  position: relative;
  overflow: hidden;
  padding-bottom: 80px;
  max-height: 0;
  transition: all .25s ease;

  &:after {
    content: '';
    display: block;
    width: 100%; height: 1px;
    background: $almost-white;
    position: absolute;
    left: 0; top: 0;
    opacity: 0;
    transition: opacity .25s ease;
  }

  &.is-expanded {
    max-height: 3000px;
    opacity: 1;

    &:after {
      opacity: 1;
    }
  }
}
